import React from 'react'
import Word from 'containers/storyIntro/Word'
import { converterWord, wordSimplified } from 'utils/helperFunction'

interface IStoryTitleProps {
  storyTitleContent?: any
  showPinYin: any
  story: any
  handleClickWord: any
  activeIndex: any
  chineseWordFontSize?: string
}

const StoryTitle: React.FC<IStoryTitleProps> = (props: any) => {
  const { storyTitleContent, story, activeIndex, handleClickWord, chineseWordFontSize } = props

  return (
    <>
      {storyTitleContent?.map((wordObj, index) => {
        const stripedWord = wordObj.word.replace(/<[^>]+>/g, '')
        const newWord = story?.newWordsArray?.find(
          (wordDetail) => converterWord(stripedWord) === converterWord(wordDetail?.word),
        )
        const learningWord = story?.learningWords?.find(
          (wordDetail) => converterWord(stripedWord) === converterWord(wordDetail?.word),
        )
        const growingWord = story?.growingWords?.find(
          (wordDetail) => converterWord(stripedWord) === converterWord(wordDetail?.word),
        )
        const developingWord = story?.developingWords?.find(
          (wordDetail) => converterWord(stripedWord) === converterWord(wordDetail?.word),
        )
        const masteredWord = story?.masteredWords?.find(
          (wordDetail) => converterWord(stripedWord) === converterWord(wordDetail?.word),
        )
        const isOldWord = story?.learningWords?.length > 0 ? !!learningWord : story?.oldWords?.includes(wordObj.word)
        const isNewWord =
          story?.newWordsArray?.length > 0
            ? !!newWord
            : (!story?.newWord1 ? false : story?.newWord1 === wordObj.word) ||
              (!story?.newWord2 ? false : story?.newWord2 === wordObj.word)
        const isIncludePTag =
          wordObj.word.includes('<p>') || wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')
        return (
          <React.Fragment key={index}>
            {isIncludePTag && index ? <br /> : ''}
            {stripedWord.length ? (
              <Word
                key={index}
                index={`T${index}`}
                isActive={`T${index}` === activeIndex}
                text={stripedWord}
                isNewWord={isNewWord}
                isGrowingWord={!!growingWord}
                isDevelopingWord={!!developingWord}
                isMasteredWord={!!masteredWord}
                isOldWord={!isNewWord && isOldWord}
                textObj={wordObj}
                sentance={storyTitleContent}
                onClick={handleClickWord}
                chineseWordFontSize={'30px'}
                pinyinFontSize={chineseWordFontSize?.pinyinValue}
                isFontBold={true}
                pinYinText={wordObj?.pinyin ? wordObj?.pinyin : null}
              />
            ) : (
              ''
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default StoryTitle
