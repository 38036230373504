import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import store, { persistor, RootReducer } from './redux/stores'
import { checkAuthen } from './redux/actions/userActions'
import { checkStoryExpiration } from '../src/hooks/useCheckStory'
import { resetStory } from './redux/actions/storyAction'
import { CHOOSE_LANGUAGE, STORIES } from './routes/routeConstant'
import { SET_STEPPER_STEP } from './redux/types'
import { PostHogProvider } from 'posthog-js/react'
import { postHogOptions, REACT_APP_PUBLIC_POSTHOG_KEY } from './redux/actions/config'
import { redirect, BrowserRouter } from 'react-router-dom'

const check = async (currentStory: any, level: string, memberId: string) => {
  const isUpdated = checkStoryExpiration(currentStory)
  if (isUpdated) {
    await resetStory(level, memberId, currentStory.id)
    return isUpdated
  }
  return false
}
declare global {
  interface Window {
    dataLayer: any[]
  }
}

const CheckAuthApp = () => {
  const dispatch = useDispatch()
  const { pathname } = window.location
  const { isLeadUser } = useSelector(({ onboarding }: RootReducer) => onboarding)

  // temporarily added logic

  if (pathname.includes('/success-paid')) {
    dispatch({ type: SET_STEPPER_STEP, payload: isLeadUser ? 5 : 3 })
    checkAuthen()
  }

  // this logic will be set after a/b testing
  // window.location.pathname.includes('/onboarding') || window.location.pathname.includes('/success-paid'))

  if (pathname.includes('/onboarding')) {
    dispatch({ type: SET_STEPPER_STEP, payload: isLeadUser ? 5 : 3 })
    checkAuthen()
  }

  window.dataLayer.push({
    event: 'onboarding',
    page: {
      url: location,
      title: 'maayot • Learn Chinese as You Read It',
    },
  })
  useEffect(() => {
    if (
      pathname.includes('integrations/beeminder') ||
      pathname.includes(CHOOSE_LANGUAGE) ||
      pathname.startsWith('/learn')
    ) {
      console.log('return from check')
      return
    }
    checkAuthen().then(async () => {
      console.log('checkAuthen called')
      const currentStory = store?.getState()?.story?.currentStory
      const credentials = store?.getState()?.user?.credentials
      const { memberId } = credentials
      const level = store?.getState()?.story?.storyLevel
      let checkRes = false
      if (currentStory?.id) {
        checkRes = await check(currentStory, level, memberId)
      }
      if (checkRes) {
        redirect(STORIES)
      }
    })
  }, [])
  return <App />
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <PostHogProvider apiKey={REACT_APP_PUBLIC_POSTHOG_KEY || ''} options={postHogOptions}>
        <BrowserRouter>
          <CheckAuthApp />
        </BrowserRouter>
      </PostHogProvider>
    </PersistGate>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
