import store from '../stores'
import {
  SET_ERRORS,
  SET_CURRENT_STEP,
  SET_STORY,
  SET_STORIES,
  SET_LAST_STEP,
  SET_PREVENT_REDIRECT,
  SET_SHOW_RECORDING_SUCCESS,
  SET_STORY_LOADING,
  RESET_QUIZ,
  SET_QUESTION,
  SET_QUESTION_ANSWER_LIST,
  SET_CURRENT_STREAK,
  ACCOUNT_SET_LEVEL_DONE,
  SET_STORIES_LIST_LOADER,
  SET_STORIES_LIST,
  SET_IS_SUBMIT,
  SET_PINYIN_SETTINGS,
  SET_WORD_DETAIL,
  SET_WORD_TYPES,
  CHECK_WORD_IS_CLICKED,
} from '../types'
import MAAYOT_API, { headers } from './config'
import { MemberStepInStory } from 'shared/constant'
import axios from 'axios'
import axiosInstance from '../../axios/axiosInstance'
import {
  CheckStorySubmissionProps,
  GetCurrentStoriesProps,
  GetWordDetailProps,
  UpdatePinyinSettingProps,
  WordClickProps,
  WordContextProps,
  wordDoesNotKnowProps,
  WordDoKnowProps,
} from '../../types/index'
// var tokenize = require("chinese-tokenizer").loadFile('./cedict_ts.u8');

export const getLastStep = (storyId: string, memberId: string, level: string) => {
  if (!storyId || !level || !memberId) {
    return
  }
  const res: any = axios.get(
    `${MAAYOT_API}tracking?storyId=${storyId}&memberId=${memberId}&level=${level?.toLowerCase() || ''}`,
    {
      headers,
    },
  )
  return res?.data
}

export const getOldWord = async () => {
  const res: any = await axios.get(`${MAAYOT_API}words/past-new-words`, {
    headers,
  })
  return res?.data
}

export const getIntro = (memberId: string, level: string) => (dispatch: any) => {
  if (!level || !memberId) {
    return Promise.reject('Invalid params')
  }
  axios
    .get(`${MAAYOT_API}prismic/getIntro?memberId=${memberId}&level=${level?.toLowerCase() || ''}`, {
      headers,
    })

    .then(({ data: dataStory }) => {
      if (dataStory && JSON.stringify(dataStory) !== '{}') {
        return Promise.all([dataStory, getLastStep(dataStory.id, memberId, level), getOldWord()])
      } else {
        dispatch({
          type: SET_ERRORS,
          payload: 'Story not found',
        })
        return Promise.reject("'Story not found',")
      }
    })
    .then((res) => {
      const [dataStory, lastStep, oldWordRes] = res
      dataStory.storyContent = dataStory.arContentWithTags
      dataStory.storyIntroduction = dataStory.storyIntroduction.replaceAll(/\s/g, ' ')

      dataStory.arSentencesWord11 = dataStory?.arSentencesWord11 ? dataStory.arSentencesWord11 : []
      dataStory.arSentencesWord12 = dataStory?.arSentencesWord12 ? dataStory.arSentencesWord12 : []
      dataStory.arSentencesWord21 = dataStory?.arSentencesWord21 ? dataStory.arSentencesWord21 : []
      dataStory.arSentencesWord22 = dataStory?.arSentencesWord22 ? dataStory.arSentencesWord22 : []

      dataStory.arWordSen11 = dataStory.arSentencesWord11.map((word) => word.word)
      dataStory.arWordSen12 = dataStory.arSentencesWord12.map((word) => word.word)
      dataStory.arWordSen21 = dataStory.arSentencesWord21.map((word) => word.word)
      dataStory.arWordSen22 = dataStory.arSentencesWord22.map((word) => word.word)
      dataStory.arContent = [
        ...dataStory.arContent,
        ...dataStory.arSentencesWord11,
        ...dataStory.arSentencesWord12,
        ...dataStory.arSentencesWord21,
        ...dataStory.arSentencesWord22,
      ]

      let oldWords
      if (oldWordRes?.length) {
        oldWords = []
        oldWordRes.forEach((wordObj) => {
          const { data = {} } = wordObj || {}
          const { word, level: objLevel } = data || {}
          if (objLevel?.toLowerCase() == level.toLowerCase()) {
            oldWords.push(word)
          }
        })
      }
      dispatch({
        type: SET_STORY,
        payload: { ...dataStory, oldWords },
      })
      dispatch({
        type: SET_LAST_STEP,
        payload: lastStep?.data?.[0]?.data?.step,
      })
    })
    .catch(() => {
      dispatch({
        type: SET_ERRORS,
        payload: 'Story not found',
      })
    })
}

export const getStory = (id: string, level: string, memberId: string) => (dispatch: any) => {
  if (!id || !level || !memberId) {
    return Promise.reject('Invalid params')
  }
  dispatch({
    type: SET_STORY_LOADING,
    payload: { storyLoading: true },
  })
  fetch(`${MAAYOT_API}prismic/findStory?storyId=${id}&level=${level.toLowerCase()}&memberId=${memberId}`, {
    method: 'GET',
    headers,
  })
    .then((resData) => {
      return resData.json()
    })
    .then((dataStory) => {
      if (dataStory && JSON.stringify(dataStory) !== '{}') {
        return Promise.all([dataStory, getLastStep(dataStory.id, memberId, level), getOldWord()])
      } else {
        dispatch({
          type: SET_STORY_LOADING,
          payload: { storyLoading: false },
        })
        dispatch({
          type: SET_ERRORS,
          payload: 'Story not found',
        })
        return Promise.reject("'Story not found',")
      }
    })
    .then((res) => {
      const [dataStory, lastStep, oldWordRes] = res
      dataStory.englishContent = dataStory.englishContent
      dataStory.storyContent = dataStory.arContentWithTags

      dataStory.arSentencesWord11 = dataStory?.arSentencesWord11 ? dataStory.arSentencesWord11 : []
      dataStory.arSentencesWord12 = dataStory?.arSentencesWord12 ? dataStory.arSentencesWord12 : []
      dataStory.arSentencesWord21 = dataStory?.arSentencesWord21 ? dataStory.arSentencesWord21 : []
      dataStory.arSentencesWord22 = dataStory?.arSentencesWord22 ? dataStory.arSentencesWord22 : []

      dataStory.arWordSen11 = dataStory.arSentencesWord11.map((word) => word.word)
      dataStory.arWordSen12 = dataStory.arSentencesWord12.map((word) => word.word)
      dataStory.arWordSen21 = dataStory.arSentencesWord21.map((word) => word.word)
      dataStory.arWordSen22 = dataStory.arSentencesWord22.map((word) => word.word)
      dataStory.arContent = [
        ...dataStory.arContent,
        ...dataStory.arSentencesWord11,
        ...dataStory.arSentencesWord12,
        ...dataStory.arSentencesWord21,
        ...dataStory.arSentencesWord22,
      ]
      let oldWords
      if (oldWordRes?.length) {
        oldWords = []
        oldWordRes.forEach((wordObj) => {
          const { data = {} } = wordObj || {}
          const { word, level: objLevel } = data || {}
          if (objLevel?.toLowerCase() == level.toLowerCase()) {
            oldWords.push(word)
          }
        })
      }
      dispatch({
        type: SET_STORY,
        payload: { ...dataStory, oldWords },
      })
      dispatch({
        type: SET_LAST_STEP,
        payload: lastStep?.data?.[0]?.data?.step,
      })
      dispatch({
        type: SET_STORY_LOADING,
        payload: { storyLoading: false },
      })
      return Promise.resolve({ ...dataStory, oldWords })
    })
    .catch((err) => {
      console.error(err)
      dispatch({
        type: SET_ERRORS,
        payload: 'Story not found',
      })
      dispatch({
        type: SET_STORY,
        payload: null,
      })
      dispatch({
        type: SET_STORY_LOADING,
        payload: { storyLoading: false },
      })
      return Promise.reject(err)
    })
}

export const setStories = (stories: any) => (dispatch: any) => {
  // console.log('setStories', stories)
  dispatch({
    type: SET_STORIES,
    payload: stories,
  })
}

export const getStories = (perPage: string, page: string, memberId: string, level: string) => async (dispatch: any) => {
  return new Promise((resolve, reject) => {
    try {
      // fetch(`${MAAYOT_API}story-correction/closed-corrections?memberId=${memberId}`, {

      fetch(`${MAAYOT_API}prismic?par_page=${perPage}&page=${page}&level=${level.toLowerCase()}&memberId=${memberId}`, {
        method: 'GET',
        headers,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data && Object.keys(data) && Object.keys(data).length > 0) {
            // console.log('data', data)

            // dispatch({
            //   type: SET_CORRECTION_ANSWER,
            //   payload: data.answers,
            // })
            dispatch({
              type: SET_STORIES,
              payload: data,
            })
            // resolve(data.answers)
            resolve({
              data: data,
            })
          } else {
            // dispatch({
            //   type: SET_CORRECTION_ERRORS,
            //   payload: 'Error',
            // })
            reject('Error')
          }
        })
    } catch (error) {
      console.log('error')
    }
  })
}

export const setStep = (step: any) => {
  store.dispatch({
    type: SET_CURRENT_STEP,
    payload: step,
  })
}

export const setShowRecordingSuccess = (show: boolean) => (dispatch: any) => {
  dispatch({
    type: SET_SHOW_RECORDING_SUCCESS,
    payload: show,
  })
}

// translate word
export const translate = (content: string) => {
  return fetch(`${MAAYOT_API}translate`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ content }),
  })
}

export const resetStory = async (level: string, memberId: string, storyId: string) => {
  if (!level || !memberId || !storyId) {
    return
  }
  const step = MemberStepInStory.INTRODUCTION
  const content = {
    step,
    level: level?.toLowerCase() || '',
    memberId,
    storyId,
  }
  console.log('content', content)
  store.dispatch({
    type: ACCOUNT_SET_LEVEL_DONE,
    payload: level.toLowerCase() || '',
  })
  store.dispatch({
    type: SET_LAST_STEP,
    payload: step,
  })
  store.dispatch({
    type: SET_STORY,
    payload: {},
  })

  store.dispatch({
    type: RESET_QUIZ,
    payload: {},
  })
  store.dispatch({
    type: SET_QUESTION,
    payload: {
      question: '',
      questionContent: [],
      curAnswer: '',
    },
  })
  store.dispatch({
    type: SET_QUESTION_ANSWER_LIST,
    payload: {
      answers: [],
      cursorRefId: null,
      cursorTs: null,
    },
  })
  store.dispatch({
    type: SET_CURRENT_STREAK,
    payload: null,
  })
  try {
    const res = await fetch(`${MAAYOT_API}tracking`, {
      method: 'POST',
      headers,
      body: JSON.stringify(content),
    })
    console.log('RESET STORY REDUX AND REDIRECT TO INTRODUCTION')
    return res
  } catch (error) {
    return null
  }
}

//post Current Step
export const saveLastStep = (step: string, level: string, memberId: string, storyId: string) => (dispatch: any) => {
  if (!level || !memberId || !storyId || !step) {
    return
  }
  const content = {
    step,
    level: level?.toLowerCase() || '',
    memberId,
    storyId,
  }
  dispatch({
    type: SET_LAST_STEP,
    payload: step,
  })
  return fetch(`${MAAYOT_API}tracking`, {
    method: 'POST',
    headers,
    body: JSON.stringify(content),
  })
  // .then((resData) => resData.json())
  // .then((data) => {
  //   console.log("save step done")
  // })
  // .catch(e => {
  //   console.error(e)
  // })
}

export const setPreventRedirect = (isPrevent: true) => (dispatch: any) => {
  dispatch({
    type: SET_PREVENT_REDIRECT,
    payload: isPrevent,
  })
}

export const submitRecording =
  (id: string, memberId: string, memberName: string, uploadKey: string, level: string) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
      const content = {
        memberId,
        memberName,
        storyId: id,
        recordingUrl: uploadKey,
        level,
      }
      return fetch(`${MAAYOT_API}story-listening/recording`, {
        method: 'POST',
        headers,
        body: JSON.stringify(content),
      })
        .then((resData) => resData.json())
        .then((data) => {
          if (data && !data.status && Object.keys(data) && Object.keys(data).length > 0) {
            resolve(data)
          } else {
            dispatch({
              type: SET_ERRORS,
              payload: data.message.message,
            })
            reject(data.message.message)
          }
        })
    })
  }

export const getStoryRecording = (storyId: string, memberId: number) => (dispatch: any) => {
  return new Promise((resolve, reject) => {
    return fetch(`${MAAYOT_API}story-listening/check-exist-story-recording?storyId=${storyId}&memberId=${memberId}`, {
      method: 'GET',
      headers,
    })
      .then((resData) => resData.json())
      .then((data) => {
        if (data && !data.status && Object.keys(data) && Object.keys(data).length > 0) {
          resolve(data)
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: data.message.message,
          })
          reject(data.message.message)
        }
      })
  })
}

export const getCurrentStories = async ({ memberId, level, plan }: GetCurrentStoriesProps) => {
  try {
    store.dispatch({ type: SET_STORIES_LIST_LOADER, payload: true })
    const res = await axiosInstance.get(
      `prismic/getIntroDB?memberId=${memberId}&level=${level?.toLowerCase() || ''}&plan=${plan}`,
    )
    store.dispatch({ type: SET_STORIES_LIST, payload: res?.data })
    store.dispatch({ type: SET_PINYIN_SETTINGS, payload: res?.data?.pinyin_setting })
    store.dispatch({ type: SET_STORIES_LIST_LOADER, payload: false })
    return res?.data
  } catch (error) {
    store.dispatch({ type: SET_STORIES_LIST_LOADER, payload: false })
  }
}

export const checkStorySubmission = async (data: CheckStorySubmissionProps) => {
  try {
    const res = await axios.post(`${MAAYOT_API}story-listening/check-story-submission`, data, { headers })
    store.dispatch({ type: SET_IS_SUBMIT, payload: res?.data })
    return res?.data
  } catch (error) {
    console.log('error', error)
  }
}

export const wordDoesNotKnow = async (data: wordDoesNotKnowProps) => {
  try {
    const res = await axiosInstance.post(`words/update-clicked-word`, data)

    store.dispatch({ type: CHECK_WORD_IS_CLICKED, payload: false })
    store.dispatch({ type: SET_WORD_TYPES, payload: res?.data })
    return res?.data
  } catch (error) {
    // store.dispatch({
    //   type: SET_ERRORS,
    //   payload: 'something went wrong',
    // })
    console.log('error', error)
  }
}

export const wordDoKnow = async (data: WordDoKnowProps) => {
  try {
    const res = await axiosInstance.post(`words/update-not-clicked-words`, data)

    return res?.data
  } catch (error) {
    store.dispatch({
      type: SET_ERRORS,
      payload: 'something went wrong',
    })
  }
}

export const getWordDetail = async (data: GetWordDetailProps) => {
  try {
    store.dispatch({ type: SET_WORD_DETAIL, payload: { loader: true, detail: data?.detail } })
    const res = await axiosInstance.post(`words/get-clicked-word`, data?.payload)
    const wordDetail = {
      word: data?.detail?.word || res?.data?.word,
      pinyin: data?.detail?.pinyin || res?.data?.pinyin,
      english: data?.detail?.english || res?.data?.english,
      type: data?.wordType,
      importance: res?.data?.importance,
      mastery: res?.data?.mastery,
      last_seen: res?.data?.last_seen,
      test_level: res?.data?.test_level,
      frequency: res?.data?.frequency,
      sentence: res?.data?.sentence,
      sentenceString: data?.detail?.sentenceString,
    }
    store.dispatch({
      type: SET_WORD_DETAIL,
      payload: { loader: false, detail: wordDetail },
    })

    store.dispatch({ type: CHECK_WORD_IS_CLICKED, payload: false })
    return res?.data
  } catch (error) {
    store.dispatch({ type: SET_WORD_DETAIL, payload: { loader: false, detail: { ...data?.detail } } })
    // store.dispatch({
    //   type: SET_ERRORS,
    //   payload: 'something went wrong',
    // })
  }
}

export const updatePinyinSetting = async ({ data, memberId }: UpdatePinyinSettingProps) => {
  try {
    const res = await axiosInstance.patch(`user-language/pinyin/update-user-pinyin-setting?user_id=${memberId}`, data)
    store.dispatch({ type: SET_PINYIN_SETTINGS, payload: res?.data?.data })
    return res?.data
  } catch (err) {
    console.log('update pinyin api call fail')
  }
}

export const wordClick = async (data: WordClickProps) => {
  try {
    const res = await axiosInstance.post(`words/change-clicked-word-status`, data)
    store.dispatch({ type: CHECK_WORD_IS_CLICKED, payload: true })
    store.dispatch({ type: SET_WORD_TYPES, payload: res?.data })
    return res?.data
  } catch (err) {
    console.log('wordClick api call fail')
  }
}

export const wordContext = async (data: WordContextProps) => {
  try {
    const res = await axiosInstance.post(`words/get-word-context`, data)

    return res?.data
  } catch (err) {
    console.log('wordContext api call fail')
  }
}
