import React, { useEffect } from 'react'
import './App.css'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import PrivateRoute from './routes/PrivateRoute'
import MembersAccount from './components/account'
import Login from './components/login/Login'
import Introduction from './components/introduction/Introduction'
import Story from './components/story/Story'
import Quiz from './components/quiz/Quiz'
import Question from './components/question'
import SucessPaid from './components/success-paid'
import { NotFound } from './components/404/404'
import StoryFinished from './components/story-finished'
import Success from './components/success'
import Dashboard from 'components/dashboard/Dashboard'
import CreateMeet from 'components/meet/CreateMeet'
import Meet from 'components/meet/Meet'

import {
  ACCOUNT,
  NOT_FOUND,
  STORY_FINISHED,
  MEMBERS_ACCOUNT,
  INTRODUCTION,
  LOGIN,
  STORY,
  QUIZ,
  QUESTION,
  SUCCESS_PAID,
  SUCCESS,
  DASHBOARD,
  CREATE_MEET,
  LIVE_MEET,
  PRO_MEET,
  CORRECTIONS,
  PASTSTORIES,
  STORY_STREAKS,
  CHOOSE_LANGUAGE,
  NEW_USER_PATH,
  OLD_USER_PATH,
  LEAD_USER_PATH,
  STORIES,
  LEAD_USER2_PATH,
  NEW_USER2_PATH,
  HOME,
  USER,
} from 'routes/routeConstant'
import Corrections from 'components/Corrections/Corrections'
import SkritterCallback from 'components/skritter/CallbackComponent'
import StoryCompletedStreak from 'components/StoryStreak'
import ChooseLanguage from 'components/choose-language'
import OnBoarding from 'components/onboarding'
import PastStories from 'components/stories/PastStories'
import Stories from 'components/landing-page'
import { REACT_APP_GTM_ID } from './redux/actions/config'
import { useSelector } from 'react-redux'
import posthog from 'posthog-js'
import { RootReducer } from './redux/stores'
// import Meet from 'components/meet/Meet'

globalThis.characterPreference = ''

const tagManagerArgs = {
  gtmId: REACT_APP_GTM_ID || '',
}

TagManager.initialize(tagManagerArgs)

function App() {
  const { pathname } = useLocation()
  const { authenticated, credentials } = useSelector(({ user }: RootReducer) => user)
  const { languageInfo, userType } = useSelector(({ onboarding }: RootReducer) => onboarding)
  // check height element

  window.dataLayer.push({
    event: 'pageview',
    page: {
      url: location,
      title: 'maayot • Learn Chinese as You Read It',
    },
  })

  // const footerRender =
  //   history?.location?.pathname.includes('integrations/beeminder') ||
  //   history?.location?.pathname.includes(CHOOSE_LANGUAGE) ||
  //   history?.location?.pathname.startsWith('/learn')
  //     ? false
  //     : true

  useEffect(() => {
    if (authenticated && credentials?.memberId) {
      posthog.identify(`${credentials?.memberId}`, {
        memberId: credentials?.memberId,
        name: credentials?.metaData?.firstName || credentials?.customFields?.['first-name'],
      })
    }
  }, [authenticated])

  useEffect(() => {
    const exitsScript = document.getElementById('temp')
    const script = document.createElement('script')
    script.src = 'https://api.memberstack.io/static/memberstack.js?webflow'
    script.setAttribute('data-memberstack-id', '16994eed7bdde3781a57968be24dd38e')
    script.async = true

    // document.body.removeChild(script)
    exitsScript && document.head.removeChild(exitsScript)
    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [pathname])

  return (
    <div className='App'>
      <Routes>
        <>
          <Route path={LOGIN} element={<Login />} />
          <Route path={CHOOSE_LANGUAGE} element={<ChooseLanguage />} />
          <Route path={NEW_USER_PATH} element={<OnBoarding />} />
          <Route path={OLD_USER_PATH} element={<OnBoarding />} />
          <Route path={LEAD_USER_PATH} element={<OnBoarding />} />
          {/* temp route */}
          <Route path={NEW_USER2_PATH} element={<OnBoarding />} />
          <Route path={LEAD_USER2_PATH} element={<OnBoarding />} />
          <Route
            path={'/onboarding'}
            element={<Navigate to={`/learn-${languageInfo?.language?.toLowerCase()}/${userType}`} />}
          />
          <Route
            path={SUCCESS_PAID}
            element={<Navigate to={`/learn-${languageInfo?.language?.toLowerCase()}/${userType}2`} />}
          />
          <Route path={HOME} element={<Navigate to={STORIES} />} />
          <Route path={USER} element={<Navigate to={STORIES} />} />
          <Route path={STORIES} element={<PrivateRoute component={Stories} />} />
          <Route path={INTRODUCTION} element={<PrivateRoute component={Introduction} showNav={true} />} />
          <Route path={`${STORY}/:id`} element={<PrivateRoute component={Story} showNav={true} />} />
          <Route path={`${QUIZ}/:id`} element={<PrivateRoute component={Quiz} showNav={true} />} />
          <Route path={`${QUESTION}/:id`} element={<PrivateRoute component={Question} showNav={true} />} />
          <Route path={STORY_FINISHED} element={<PrivateRoute component={StoryFinished} showNav={true} />} />
          <Route path={PASTSTORIES} element={<PrivateRoute component={PastStories} />} />
          <Route path={CORRECTIONS} element={<PrivateRoute component={Corrections} />} />
          <Route path={ACCOUNT} element={<PrivateRoute component={MembersAccount} />} />
          <Route path={SUCCESS} element={<PrivateRoute component={Success} />} />
          <Route path={DASHBOARD} element={<PrivateRoute component={Dashboard} showDashboard={true} />} />
          <Route path={CREATE_MEET} element={<PrivateRoute component={CreateMeet} isMeeting={true} />} />
          <Route path={`${LIVE_MEET}/:id`} element={<PrivateRoute component={Meet} />} />
          <Route path={`${PRO_MEET}/:id`} element={<PrivateRoute component={Meet} />} />
        </>
        <Route path={MEMBERS_ACCOUNT} element={<Navigate to={STORIES} />} />
        <Route path={'/callback'} element={<SkritterCallback />} />
        <Route path={`${STORY_STREAKS}/:memberId/storiesread`} element={<StoryCompletedStreak />} />
        <Route path={SUCCESS_PAID} element={<SucessPaid />} />
        <Route path={NOT_FOUND} element={<NotFound />} />
        <Route path='*' element={<Navigate to={NOT_FOUND} />} />
      </Routes>
      {/* {footerRender && <Footer />} */}
    </div>
  )
}

export default App
