import React from 'react'
import { Link } from 'react-router-dom'
import PLayer from '../player/Player'
import { INTRODUCTION, STORY_FINISHED } from 'routes/routeConstant'

interface INavigationFooterProps {
  className?: string
  audio?: string
  nextPath?: string
  backPath?: string
  nextText?: string
  backText?: string
  nextOnclick?: any
  backOnclick?: any
  allowRecording?: boolean
}

const NavigationButton = (props) => {
  const { text, isNext = false, pathname, onClick } = props
  const renderBtn = (
    <button className={`button-2 ${isNext ? '' : ' bg-gray-4'} w-button`} onClick={onClick}>
      {text}
    </button>
  )
  return onClick ? renderBtn : <Link to={{ pathname }}>{renderBtn}</Link>
}
const NavigationFooter: React.FC<INavigationFooterProps> = (props: any) => {
  const {
    className,
    audio,
    nextPath = STORY_FINISHED,
    backPath = INTRODUCTION,
    nextText = 'Next Lessons',
    backText = 'Back',
    nextOnclick = null,
    backOnclick = null,
    allowRecording = false,
  } = props
  return (
    <div className={`section-footer sticky-footer ${className}`} id='navigation-footer'>
      {(audio && (
        <div className='w-row section-audio-mobile'>
          <PLayer audio={audio} allowRecording={allowRecording}></PLayer>
        </div>
      )) ||
        null}
      <div className='section-4 w-row section-bottom-navigation section-sticky-footer'>
        <div className='w-col-4 w-col-medium-3 w-col-small-3 w-col-tiny-6 bottom-navigation-left'>
          <NavigationButton pathname={backPath} text={backText} onClick={backOnclick} />
        </div>
        <div className='w-col-4 w-col-medium-6 w-col-small-6  section-audio-large'>
          {(audio && <PLayer audio={audio} allowRecording={allowRecording}></PLayer>) || null}
        </div>
        <div className='w-col-4 w-col-medium-3 w-col-small-3 w-col-tiny-6 bottom-navigation-right'>
          <NavigationButton pathname={nextPath} text={nextText} onClick={nextOnclick} isNext={true} />
        </div>
      </div>
    </div>
  )
}

export default NavigationFooter
