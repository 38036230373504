import React, { useEffect, useState } from 'react'
import {
  FreeTitle,
  PremiumTitle,
  StandardTitle,
  ProTitle,
  StandardFeatures,
  PremiumFeatures,
  ProPlanFeatures,
} from './const'
import { membershipNameType } from '../../shared/constant'
import { STORIES } from '../../routes/routeConstant'

interface IUpgradeProps {
  membership?: any
  credentials?: any
}

const selectedIcon = <i className='fa fa-check-circle color-primary' />
const unSelectedIcon = <i className='fa fa-circle-o' />

interface IFeatureProps {
  label?: string
  checked?: boolean
}

const FeatureItem: React.FC<IFeatureProps> = (props: any) => {
  const { label, checked } = props
  return (
    <div className={'account-feature-item'}>
      {checked ? selectedIcon : unSelectedIcon}
      <span className={'m-h6'}>{label}</span>
    </div>
  )
}

const FreeUserDescription: React.FC = () => {
  return (
    <>
      <div className='w-col session-summary '>
        As a free user, you’re only getting a new story every Sunday. Boost your Chinese with daily unique stories,
        access to past stories, one-click dictionary, exercises to test your comprehension and much more.
      </div>
      <div className={'account-session-button'}>
        <a href={'https://www.maayot.com/account'} className='button-4 w-inline-block button-normal' target={'_blank'}>
          Upgrade Now
        </a>
        <a className='button-default-o w-inline-block btn-margin-left' href={STORIES}>
          Access the Story
        </a>
      </div>
    </>
  )
}

const StandardDescription: React.FC = () => {
  return (
    <>
      <div className='w-col session-summary'>
        <div className={'account-feature-title'}>Enjoy your benefits with Standard plan</div>
        {StandardFeatures.map((label, index) => {
          return <FeatureItem key={index} label={label} checked={true} />
        })}
      </div>
      <div className={'account-session-button'}>
        <a className='button-4 w-inline-block' href={STORIES}>
          Read Now
        </a>
      </div>
    </>
  )
}

const SchoolDescription: React.FC = () => {
  return (
    <>
      <div className='w-col session-summary'>
        <div className={'account-feature-title'}>Enjoy your benefits with School plan</div>
        {StandardFeatures.map((label, index) => {
          return <FeatureItem key={index} label={label} checked={true} />
        })}
      </div>
      <div className={'account-session-button'}>
        <a className='button-4 w-inline-block' href={STORIES}>
          Read Now
        </a>
      </div>
    </>
  )
}

const PremiumDescription: React.FC = () => {
  return (
    <>
      <div className='w-col session-summary'>
        <div className={'account-feature-title'}>Enjoy your benefits with Premium plan</div>
        {PremiumFeatures.map((label, index) => {
          return <FeatureItem key={index} label={label} checked={true} />
        })}
      </div>
      <div className={'account-session-button'}>
        <a className='button-4 w-inline-block' href={STORIES}>
          Read Now
        </a>
      </div>
    </>
  )
}

const ProDescription: React.FC = () => {
  return (
    <>
      <div className='w-col session-summary'>
        <div className={'account-feature-title'}>Enjoy your benefits with Pro plan</div>
        {ProPlanFeatures.map((feature, index) => {
          return <FeatureItem key={index} label={feature.label} checked={true} />
        })}
      </div>
      <div className={'account-session-button'}>
        <a className='button-4 w-inline-block' href={STORIES}>
          Read Now
        </a>
      </div>
    </>
  )
}

const Upgrade: React.FC<IUpgradeProps> = (props: any) => {
  const { membership = { name: 'maayot Free' } } = props
  const [title, setTitle] = useState('')
  useEffect(() => {
    let title = ''
    if (membership) {
      switch (membership.name) {
        case membershipNameType.Premium:
          title = PremiumTitle[Math.floor(Math.random() * PremiumTitle.length)]
          break
        case membershipNameType.School:
        case membershipNameType.Standard:
          title = StandardTitle[Math.floor(Math.random() * StandardTitle.length)]
          break
        case membershipNameType.Free:
          title = FreeTitle[Math.floor(Math.random() * FreeTitle.length)]
          break
        case membershipNameType.Pro:
          title = ProTitle[Math.floor(Math.random() * ProTitle.length)]
          break
      }
    }
    setTitle(title)
  }, [membership])
  return (
    <>
      <div className='main-session'>
        <div className='w-row'>
          <div className='w-col w-col-8 content-left content-sm-center'>
            <div className='session-title account-session-title'>
              <h2 className={'m-h2 '}>{title}</h2>
            </div>
            {(membership?.name == membershipNameType.Free && <FreeUserDescription />) ||
              (membership?.name == membershipNameType.Premium && <PremiumDescription />) ||
              (membership?.name == membershipNameType.Pro && <ProDescription />) ||
              (membership?.name == membershipNameType.School && <SchoolDescription />) || <StandardDescription />}
          </div>
          <div className='w-col w-col-4 section-story-img m-center m-al-c'>
            <img className='account-image' alt={''} src={'images/my-account-upgrade.png'} />
          </div>
        </div>
      </div>
    </>
  )
}
export default Upgrade
