import * as React from 'react'
import LeftStory, { Word } from '../../containers/story/LeftStory'
import Translate from '../../containers/popup/Translate'
import { useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { translate } from '../../redux/actions/storyAction'
import { characterConst, converterType, wordType } from '../../shared/constant'
import { AddWordToHackChinese, handleAddWordToSkritter } from '../../redux/actions/userActions'
import NewWord from 'containers/story/NewWord'

interface IStoryProps {
  story: any
  user_auth_token: any
  skritter_token: any
}
const Story: React.FC<IStoryProps> = (props: any) => {
  const { story, user_auth_token } = props
  const contentStoryRef = useRef(document.createElement('div'))
  const popupRef = useRef<HTMLInputElement>(null)
  const [transVisible, setTransVisible] = useState(false)
  const [transData, setTransData] = useState({})
  const [activeIndex, setActiveIndex] = useState('-1')

  const { newWord1, newWord2, oldWords } = story || {}

  const newWordCon1 = !story?.newWord1
    ? ''
    : globalThis.characterPreference === characterConst.traditional
      ? converterType.converterTraditional(story?.newWord1)
      : converterType.converterSimplified(story?.newWord1)
  const newWordCon2 = !story?.newWord2
    ? ''
    : globalThis.characterPreference === characterConst.traditional
      ? converterType.converterTraditional(story?.newWord2)
      : converterType.converterSimplified(story?.newWord2)

  useEffect(() => {
    document.addEventListener('scroll', onTranslateClose)
    document.addEventListener('click', onDocumentClick)
    return () => {
      document.removeEventListener('scroll', onTranslateClose)
      document.removeEventListener('click', onDocumentClick)
    }
  }, [contentStoryRef, props.story])

  const setTranslate = (word: string, posTop: number, posLeft: number, data: any) => {
    const isOldWord = story?.oldWords?.includes(word)
    const type =
      newWordCon1 === word || newWordCon2 === word ? wordType.NewWord : isOldWord ? wordType.OldWord : wordType.Word
    setTransVisible(true)
    setTransData({
      type,
      top: posTop,
      left: posLeft,
      word,
      definition: data.pinyin,
      example1: data.english,
      example2: '',
      level: 'HSK 5',
    })
  }

  const handleClickWord = (event, key) => {
    handleTranslate(event)
    setActiveIndex(activeIndex === key ? `-1` : key)
    event.preventDefault()
    event.stopPropagation()
  }

  const trans = (word: string, posTop: number, posLeft: number) => {
    if (/^[&.-”“，、；？@!#$%^&*()]+$/.test(word) || word.trim() === '。') {
      setTransVisible(false)
      return
    }
    const wordTranslate = story.arContent.filter(
      (iWord) =>
        (globalThis.characterPreference === characterConst.traditional
          ? converterType.converterTraditional(iWord.word)
          : converterType.converterSimplified(iWord.word)) === word,
    )
    if (wordTranslate && wordTranslate.length > 0) {
      setTranslate(word, posTop, posLeft, wordTranslate[0])
      if (user_auth_token) {
        AddWordToHackChinese(wordTranslate[0], user_auth_token)
      }
      if (props?.skritter_token) {
        handleAddWordToSkritter({
          word,
          translate: wordTranslate[0],
          title: props?.story?.storyTitleContent,
          skritter_token: props?.skritter_token,
        })
      }
    } else {
      translate(word)
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          if (data && data.length > 0) {
            setTranslate(word, posTop, posLeft, data[0])
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const handleTranslate = (event) => {
    if (
      (event.type === 'mouseover' || event.type === 'click') &&
      event.target.innerText &&
      event.target.innerText.length > 0
    ) {
      trans(event.target.innerText, event.clientY, event.clientX)
    }
  }

  const onTranslateClose = () => {
    setTransVisible(false)
  }
  const onDocumentClick = (e) => {
    if (!popupRef?.current?.contains(e.target)) {
      setTransVisible(false)
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <>
          {story?.storyContent?.map((wordObj, index) => {
            const isOldWord = story?.oldWords?.includes(wordObj.word)
            const isIncludePTag =
              wordObj.word.includes('<p>') || wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')
            const stripedWord = wordObj.word.replace(/<[^>]+>/g, '')
            return (
              <React.Fragment key={index}>
                {isIncludePTag && index ? <br /> : ''}
                {stripedWord.length ? (
                  <>
                    <Word
                      key={index}
                      index={index}
                      text={stripedWord}
                      isNewWord={stripedWord === story?.newWord1 || stripedWord === story?.newWord2}
                      isOldWord={isOldWord}
                      onClick={handleClickWord}
                      pinYinText={story.showPinYin ? wordObj?.pinyin : null}
                    />
                  </>
                ) : (
                  ''
                )}
              </React.Fragment>
            )
          })}
        </>
      </div>
      <Translate ref={popupRef} visible={transVisible} data={transData} onClose={onTranslateClose} />
    </div>
  )
}

export default Story
