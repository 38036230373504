import React, { useEffect } from 'react'
import Loader from '../../containers/loader/loader'

const SuccessPaid: React.FC = () => {
  useEffect(() => {
    window.location.href = 'https://maayot.com/success-paid'
  }, [])
  return <Loader />
}
export default SuccessPaid
