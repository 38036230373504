import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useSelector } from 'react-redux'
import { characterConst, reviewList } from 'shared/constant'
import Marquee from 'react-fast-marquee'
import { useLocation } from 'react-router-dom'
import { ReviewStarIcon } from 'components/icons/icons'
import { RootReducer } from '../../redux/stores'

const FreeTrialSignUp = () => {
  const { pathname } = useLocation()
  const { languageInfo, preferenceType } = useSelector(({ onboarding }: RootReducer) => onboarding)

  useEffect(() => {
    AOS.init()
  }, [])

  useEffect(() => {
    if (pathname.includes('/lead')) {
      const exitsScript = document.getElementById('temp')
      const script = document.createElement('script')
      script.src = 'https://api.memberstack.io/static/memberstack.js?webflow'
      script.setAttribute('data-memberstack-id', '16994eed7bdde3781a57968be24dd38e')
      script.async = true

      // document.body.removeChild(script)
      exitsScript && document.head.removeChild(exitsScript)
      document.head.appendChild(script)

      return () => {
        document.head.removeChild(script)
      }
    }
  }, [])

  return (
    <>
      <div className='trusted_learners_grid_div'>
        <div className='left_sec_div'>
          <div className='logo_div mob_hide_img'>
            <img className='logo_img' src={'/images/maayot-green-logo-small.svg'} alt='Image' />
          </div>
          <div className='choose-lunguage-main-div supercharge_main_div'>
            <div className='common-title-sec'>
              <h2
                className='screen-sub-title mb-3'
                data-aos='fade-down'
                data-aos-duration='1000'
                data-aos-once='true'
                data-aos-delay='100'
              >
                Supercharge your {languageInfo?.language || 'Chinese'} learning
              </h2>
              <p
                className='screen-para-text fw-500'
                data-aos='fade-down'
                data-aos-duration='1000'
                data-aos-delay='400'
                data-aos-once='true'
              >
                Start your learning journey with maayot.
              </p>
            </div>
            <div className='login-field-main-div'>
              <div data-aos='fade-down' data-aos-duration='1000' data-aos-delay='1000' data-aos-once='true'>
                <form
                  id='email-form'
                  name='email-form'
                  data-name='Email Form'
                  data-ms-form='signup'
                  className='form-2 margin-top'
                >
                  <div className='form-group' style={{ display: 'none' }}>
                    <input
                      type='text'
                      style={{ visibility: 'hidden' }}
                      value={preferenceType || characterConst.simplified}
                      name='Character'
                      data-name='Character'
                      id='Character'
                      data-ms-member='character'
                    />
                  </div>
                  <div className='form-group' style={{ display: 'none' }}>
                    <input
                      type='text'
                      style={{ visibility: 'hidden' }}
                      value='Beginner'
                      name='level'
                      data-name='level'
                      id='level-2'
                      data-ms-member='level'
                    />
                  </div>
                  <div className='form-group'>
                    <input
                      type='text'
                      className='form-input-2 w-input'
                      maxLength={256}
                      name='full-name-2'
                      data-name='Full Name 2'
                      placeholder='First Name'
                      id='full-name-2'
                      data-ms-member='first-name'
                      required
                    />
                  </div>
                  <div className='form-group'>
                    <input
                      type='email'
                      className='form-input-2 w-input'
                      maxLength={256}
                      name='email-2'
                      data-name='Email 2'
                      placeholder='Email address'
                      id='email-2'
                      data-ms-member='email'
                      required
                    />
                  </div>
                  <div className='form-group' style={{ marginBottom: '0px' }}>
                    <input
                      type='password'
                      className='form-input-2 w-input'
                      maxLength={256}
                      name='Password 2'
                      data-name='Password 2'
                      placeholder='Create password'
                      id='password-2'
                      data-ms-member='password'
                      required
                    />
                  </div>
                  <button
                    type='submit'
                    data-wait='Please wait...'
                    className='common-btn-cls green-btn-class input-btn free-trial-btn'
                    data-ms-membership={'645c5deafbf0a30002812357'}
                  >
                    {'Start my 7-day free trial'}
                  </button>

                  <div className='bottom-section'>
                    <p className='links-text mt-3 light_gray_color'>
                      Cancel anytime. We’ll send you a reminder via email 2 days before your trial period ends.
                    </p>
                    <button
                      type='submit'
                      className='links-text second'
                      data-ms-membership={'5ef93844eed2c40004936aeb'}
                      style={{
                        textDecoration: 'underline',
                        fontWeight: '700',
                        fontSize: '16px',
                        backgroundColor: 'white',
                      }}
                      // onClick={() => history.push(`${pathname}2`)}
                    >
                      I don't wish to take advantage of my free trial
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className='right_sec_div'>
          <div className='w-100'>
            <div className='mob_logo_div'>
              <img className='mob_logo_img' src={'/images/maayot-text-svg.svg'} alt='Image' />
            </div>
            <div className='trusted_sec'>
              <h3 className='section_Heading'> Trusted by learners and schools </h3>

              <div className='reviews_sec'>
                <div className='review_card_box'>
                  <img className='playstore_card' src={'/images/playstore_card.png'} alt='Image' />
                </div>
                <div className='review_card_box'>
                  <img className='appstore_card' src={'/images/appstore_card.png'} alt='Image' />
                </div>
                <div className='review_card_box'>
                  <img className='school_card' src={'/images/school_card_1.png'} alt='Image' />
                </div>
                <div className='review_card_box'>
                  <img className='large_school_card' src={'/images/school_card_2.png'} alt='Image' />
                </div>
              </div>

              <div className='available_app_sec'>
                <h6 className='available_text'> Available on </h6>
                <div className='app_icon'>
                  <div className='app_icon_box'>
                    <img className='app_icon_img' src={'/images/app_store.png'} alt='appstore' />
                  </div>
                  <div className='app_icon_box'>
                    <img className='app_icon_img' src={'/images/play_store.png'} alt='playstore' />
                  </div>
                  <div className='app_icon_box'>
                    <img className='app_icon_img' src={'/images/Opera.png'} alt='opera' />
                  </div>
                  <div className='app_icon_box'>
                    <img className='app_icon_img' src={'/images/safari.png'} alt='safari' />
                  </div>
                  <div className='app_icon_box'>
                    <img className='app_icon_img' src={'/images/google_chrome.png'} alt='chrome' />
                  </div>
                  <div className='app_icon_box'>
                    <img className='app_icon_img' src={'/images/fire_fox.png'} alt='firefox' />
                  </div>
                  <div className='app_icon_box'>
                    <img className='app_icon_img' src={'/images/microsoft_edge.png'} alt='edge' />
                  </div>
                </div>
              </div>

              <div className='testimonial_margin_sec'>
                <Marquee autoFill>
                  {reviewList.map((reviews, i) => (
                    <div key={i}>
                      {reviews.map((review) => (
                        <div className={`common_testimonial_card ${review?.className}`} key={review?.username}>
                          <div className='star_div'>
                            {new Array(5).fill('')?.map((_, i) => <ReviewStarIcon key={i} />)}
                          </div>
                          <h3 className='testimonial_text'>{review.review}</h3>
                          <h4 className='host_name'>{review.username}</h4>
                        </div>
                      ))}
                    </div>
                  ))}
                </Marquee>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FreeTrialSignUp
