import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SET_ALL_WORDS, SET_IS_RESULT } from '../../redux/types'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { characterConst } from 'shared/constant'
import { getWordsList } from '../../redux/actions/onboardingAction'
import Loader from 'containers/loader/loader'
import { RightIcon, WrongIcon } from 'components/icons/icons'
import { RootReducer } from '../../redux/stores'

const HaveLearnedLanguage = ({ nextStep }) => {
  const dispatch = useDispatch()
  const { languageInfo, isLeadUser, tempUser } = useSelector(({ onboarding }: RootReducer) => onboarding)
  const isActivePreference = languageInfo?.preferences?.find((preference) => preference?.is_active)
  const [wordListLoader, setWordListLoader] = useState({ loader: false, name: '' })

  const handle = {
    navigate: (val: boolean, step: number) => {
      dispatch({ type: SET_IS_RESULT, payload: false })
      dispatch({ type: SET_IS_RESULT, payload: val })
      nextStep(step)
    },
    getWordsList: async (preference_id, preference_name, val, name, step) => {
      // if (languageInfo?.preferences?.length) {
      setWordListLoader({ loader: true, name })

      const response = await getWordsList({
        language_id: languageInfo?._id,
        temp_user_id: tempUser ? tempUser : undefined,
        preference_id: preference_id || isActivePreference?._id || null,
      })
      dispatch({
        type: SET_ALL_WORDS,
        payload: { wordList: response?.data, preferenceType: preference_name },
      })
      setWordListLoader({ loader: false, name })
      handle.navigate(val, step)
      // }
    },
    onClick: async (val: boolean, name: string, step: number) => {
      await handle.getWordsList(isActivePreference?._id || null, characterConst.simplified, val, name, step)
    },
  }

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <>
      <div className='w-container'>
        <div className='choose-lunguage-main-div'>
          <div className='common-title-sec'>
            <h2
              className='screen-sub-title'
              data-aos='fade-down'
              data-aos-duration='1000'
              // data-aos-easing='linear'
              data-aos-once='true'
              data-aos-delay='100'
            >
              Have you learned {languageInfo?.language || 'Chinese'} before?
            </h2>
            <p
              className='screen-para-text'
              data-aos='fade-down'
              data-aos-duration='1000'
              data-aos-once='true'
              data-aos-delay='100'
            >
              If you studied it before, but know less 100 words, choose "No".
            </p>
          </div>
          <div
            className='method-main-div'
            data-aos='fade-down'
            data-aos-duration='1000'
            // data-aos-easing='linear'
            data-aos-once='true'
            data-aos-delay='400'
          >
            <button
              onClick={() =>
                handle.onClick(
                  true,
                  'Yes',
                  isLeadUser ? (languageInfo?.preferences?.length ? 2 : 3) : languageInfo?.preferences?.length ? 5 : 6,
                )
              }
              disabled={wordListLoader?.loader}
              className={'method-btn outline-btn-class box-shadow-cls'}
            >
              {wordListLoader?.loader && wordListLoader?.name === 'Yes' ? (
                <Loader showText={true} color='gray' />
              ) : (
                <>
                  <label className='lung-text'>
                    <RightIcon />
                  </label>
                  Yes
                </>
              )}
            </button>
            <button
              onClick={() => handle.onClick(false, 'No', isLeadUser ? 4 : 7)}
              className={'method-btn outline-btn-class box-shadow-cls wrong'}
              disabled={wordListLoader?.loader}
            >
              {wordListLoader?.loader && wordListLoader?.name === 'No' ? (
                <Loader showText={true} color='gray' />
              ) : (
                <>
                  <label className='lung-text'>
                    <WrongIcon />
                  </label>
                  No
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default HaveLearnedLanguage
