import axios from 'axios'
import { SignJWT } from 'jose'
import { MEETING_BASE_URL, REACT_APP_VIDEO_SDK_API_KEY, REACT_APP_VIDEO_SDK_API_SECRET } from '../redux/actions/config'

export const generateSDKToken = async () => {
  const payload = {
    apikey: REACT_APP_VIDEO_SDK_API_KEY,
    version: 2,
    roles: ['CRAWLER'],
  }

  const token = await new SignJWT({ ...payload })
    .setProtectedHeader({ alg: 'HS256', typ: 'JWT' })
    .setIssuedAt()
    .setExpirationTime('10m')
    .sign(new TextEncoder().encode(REACT_APP_VIDEO_SDK_API_SECRET))
  return token
}

export const createRoom = async (customRoomId: string) => {
  try {
    const tempForm = { customRoomId: customRoomId }
    const headers = {
      Authorization: `${await generateSDKToken()}`,
      'Content-Type': 'application/json',
    }
    const res = await axios.post(`${MEETING_BASE_URL}/rooms`, tempForm, {
      headers: headers,
    })
    return res
  } catch (error) {
    return { status: 404, message: 'Something went wrong' }
  }
}

export const validateRoom = async (roomId: string) => {
  try {
    const headers = {
      Authorization: `${await generateSDKToken()}`,
      'Content-Type': 'application/json',
    }
    const res = await axios.get(`${MEETING_BASE_URL}/rooms/validate/${roomId}`, {
      headers: headers,
    })
    return res
  } catch (error) {
    return { status: 404, message: 'Something went wrong' }
  }
}

export const endSession = async (roomId: string) => {
  try {
    const tempForm = { roomId: roomId }
    const headers = {
      Authorization: `${await generateSDKToken()}`,
      'Content-Type': 'application/json',
    }
    const res = await axios.post(`${MEETING_BASE_URL}/sessions/end`, tempForm, {
      headers: headers,
    })
    return res
  } catch (error) {
    return { status: 404, message: 'Something went wrong' }
  }
}
