import { INTRODUCTION, STORY_FINISHED, STORY, QUIZ, QUESTION } from '../routes/routeConstant'
import { Converter } from 'opencc-js'

export const hongKongTZ = 'Asia/Hong_Kong'
export const SUNDAY = 'Sun'
export const offsetTrans = 25
export const hourInDay = 24
export const milisInDay = 60 * 60 * 24 * 1000
export const milisInWeek = 7 * milisInDay
export const serverDateFormat = 'YYYY-MM-DD'

export const converterType = {
  converterTraditional: Converter({ from: 'cn', to: 'hk' }),
  converterSimplified: Converter({ from: 'hk', to: 'cn' }),
}
export const countDownCheckTime = {
  Orange: 150,
  Red: 30,
}
export enum countDownLabel {
  Green = 'green',
  Orange = 'orange',
  Red = 'red',
}

export enum levelConst {
  beginner = 'beginner',
  advanced = 'advanced',
  intermediate = 'intermediate',
}
export enum characterConst {
  traditional = 'Traditional',
  simplified = 'Simplified',
}
export enum storyStep {
  Intro,
  Step1,
  Step2,
  Step3,
  Step4,
}

export enum wordType {
  NewWord,
  OldWord,
  Word,
  growingWord,
  developingWord,
  masteringWord,
}

export enum membershipType {
  Free = 'Free',
  Paid = 'Paid',
  Standard = 'Standard',
}

export enum membershipNameType {
  Free = 'maayot Free',
  Standard = 'maayot Standard',
  School = 'School',
  Premium = 'maayot Premium',
  Pro = 'maayot Pro',
  Teacher = 'maayot Teacher',
}

export enum countDownType {
  Week,
  Day,
}

export enum MemberStepInStory {
  INTRODUCTION = 'introduction',
  STORY_AND_LISTENING = 'story and listening',
  QUIZ = 'quiz',
  QUESTION = 'question',
  FINISH = 'finish',
}

export const StepMap2Link = {
  [MemberStepInStory.INTRODUCTION]: INTRODUCTION,
  [MemberStepInStory.STORY_AND_LISTENING]: STORY,
  [MemberStepInStory.QUIZ]: QUIZ,
  [MemberStepInStory.QUESTION]: QUESTION,
  [MemberStepInStory.FINISH]: STORY_FINISHED,
}

export const StepMap2Enum = {
  [MemberStepInStory.INTRODUCTION]: storyStep.Intro,
  [MemberStepInStory.STORY_AND_LISTENING]: storyStep.Step1,
  [MemberStepInStory.QUIZ]: storyStep.Step2,
  [MemberStepInStory.QUESTION]: storyStep.Step3,
  [MemberStepInStory.FINISH]: storyStep.Step4,
}

export enum accountTab {
  Streak,
  MyAccount,
  Plan,
  Level,
  Character,
  Integrations,
}

export const languageList = {
  chinese: 'Chinese',
  korean: 'Korean',
  french: 'French',
}

export const getPlanInfo: any = (name) => {
  switch (name) {
    case membershipNameType.Standard:
      return {
        mainText: 'Get teacher’s daily feedback with the Pro plan',
        subText: 'Improve your speaking, writing and grammar with daily corrections by our teacher.',
        title: 'Standard',
      }
    case membershipNameType.Free:
      return {
        mainText: 'Get more stories with the Standard plan',
        subText: 'Improve your reading and listening comprehension, and learn new words faster with a daily new story.',
        title: 'Free',
      }
    case membershipNameType.Pro:
      return {
        title: 'Pro',
      }
    case membershipNameType.Teacher:
      return {
        title: 'Teacher',
      }
    default:
      return null
  }
}

export const PlanChangeLink = 'https://www.maayot.com/account'

export const pinyinSettingOptions = [
  { label: 'Always', value: 'always' },
  { label: 'On hover', value: 'on_hover' },
  { label: 'Never', value: 'never' },
]

export const reviewList = [
  [
    {
      username: 'K Usher',
      review:
        "It's great for anyone wanting to improve their Chinese! The stories are engaging, tailored for all levels, and help me stay consistent while building vocabulary effortlessly.",
      className: 'green_card',
    },
    {
      username: 'ppshuai',
      review:
        "Maayot is an amazing tool for learning Chinese! It's easy to use, accommodates all levels, and the clear font helps with reading and writing. Great daily practice!",
      className: 'orange_card',
    },
  ],
  [
    {
      username: 'yfghbcf',
      review:
        'I love this app. I have always wanted to read and learn Chinese and this app has helped me so much. I have tried so many different approaches such as books and YouTube but this app has definitely been the most helpful and effective! Highly recommended!',
      className: 'orange_card',
    },
    {
      username: 'Ron B.',
      review: 'I love reading a new story each day with my morning coffee.',
      className: 'green_card',
    },
  ],
  [
    {
      username: 'Wayne Olson',
      review:
        "maayot is a game-changer for my Chinese studies. The massive library of graded readers is perfect for my level, and I love that I can adjust the difficulty as I improve. Plus, the app's interface is clean and intuitive. Highly recommend it to anyone learning Chinese!",
      className: 'green_card',
    },
    {
      username: 'Alvaro Garcia Lema',
      review:
        'Great app to start learning Chinese. Highly recommended. Intuitive and easy to use. 10 out of 10. China has been the past and will be the future.',
      className: 'orange_card',
    },
  ],
  [
    {
      username: 'Karina H.',
      review:
        "The best thing about how maayot teaches Chinese is how it shows a word or phrase's application in everyday speaking, and also gives insight into the ways in which language is refined for everyday conversation.",
      className: 'orange_card',
    },
    {
      username: 'Sam K.',
      review:
        'Getting every day a short text, along with a few new characters and grammar patterns has been tremendously helpful.',
      className: 'green_card',
    },
  ],

  [
    {
      username: 'F E',
      review:
        'Excellent Learning Tool!!! Maayot is an amazing app for improving my Chinese language skills. The daily reading exercises and vocabulary quizzes are incredibly helpful in making learning fun and engaging. I love how it adapts to my level and tracks my progress. The interface is easy to use, and the audio pronunciation is a great feature. This app has truly enhanced my learning experience. Highly recommend Maayot to anyone serious about learning Chinese. Five stars!',
      className: 'green_card',
    },
    {
      username: 'Yimga frank cedric',
      review: 'A great app for learning. I recommend it.',
      className: 'orange_card',
    },
  ],
  [
    {
      username: 'Jack Rolie',
      review:
        "I enjoyed their stories as they're really interesting and good. I loved how they weren't written purely to teach me how to read Chinese but also enlighten me. It has a user friendly platform which made it easy for me to navigate while learning how to read Chinese. I have no doubt that I will soon improve my Mandarin reading skills if I continue to read the daily stories.",
      className: 'orange_card',
    },

    {
      username: 'Shanell Bryant',
      review: 'Great reading material that allows you to learn at your own pace and very user friendly.',
      className: 'green_card',
    },
  ],
  [
    {
      username: 'Michael Baven',
      review:
        "I've been using maayot to improve my Chinese reading skills and I'm loving it! The app offers a variety of stories, from simple to complex, with English translations. The interactive approach has helped me build confidence in my reading abilities. The gamification elements keep me motivated and engaged. Overall, I highly recommend maayot for anyone looking to improve their Chinese reading skills.",
      className: 'green_card',
    },
    {
      username: 'Marina L.',
      review:
        'Such an underrated tool for learning Chinese; really one of my go-to for daily reading practice. Keep up the good work!',
      className: 'orange_card',
    },
  ],
  [
    {
      username: 'Erik M.',
      review:
        "These are things that I can't get with any other program, where most others don't go any deeper into the language comprehension than just the meaning of words.",
      className: 'orange_card',
    },
    {
      username: 'Roy Z.',
      review: 'I really appreciate your work and these stories!',
      className: 'green_card',
    },
  ],
  [
    {
      username: 'Bhavna Pandey',
      review:
        'This is the best app to help your reading in Chinese! I love watching my progress! It is something I can stay consistent with and the app is easy to use. Highly recommend this app!!',
      className: 'green_card',
    },
    {
      username: 'Mouad Moutaouakil',
      review: 'Nice app and works well, I learned a lot from it.',
      className: 'orange_card',
    },
  ],
  [
    {
      username: 'Vale Peña',
      review:
        'The truth is that Maayot is an excellent app when it comes to learning the Chinese language, literally one of the best there is, it is very practical and easy to use, without a doubt, highly recommended.',
      className: 'orange_card',
    },
    {
      username: 'chrishaif',
      review:
        'I’m using this app as part of my daily language learning and appreciate the simplicity of use. It’s just a nice extra tool and exercise to work on daily.',
      className: 'green_card',
    },
  ],
  [
    {
      username: 'Lazy_Tiger21',
      review:
        "I decided to learn Chinese language when I met my classmates in college, they’re from China, and they’re awesome. So, I thought, why not? My friend advised me this app, and it’s really cool. Chinese is a very difficult language, but with this app, I'm doing little steps for improving my Chinese.",
      className: 'green_card',
    },
    {
      username: 'John Nduati',
      review:
        'Fantastic app for learning Chinese! Easy to use, with clear lessons and interactive features. Perfect for a beginner like me and advanced learners alike. Highly recommend for Chinese language enthusiasts.',
      className: 'orange_card',
    },
  ],
  [
    {
      username: 'voyagebon',
      review:
        'I decided to learn Chinese as my partner is ethnically Chinese. This app has functions to help me with pronunciation and learning new words and sentences. This is my second week of using this app, hopefully I can become more fluent in Chinese later.',
      className: 'orange_card',
    },
    {
      username: 'Graham',
      review:
        'Your course is excellent and I have been doing the medium level materials for well over one year now. I also believe that it has improved my 听力。',
      className: 'green_card',
    },
  ],
  [
    {
      username: 'Mr Mercedes',
      review:
        'I absolutely love this Chinese reading app! The diverse selection of engaging stories and interactive features make learning enjoyable and effective. The vocabulary builder and audio support enhance my comprehension, and the user-friendly interface keeps me motivated. Highly recommend!',
      className: 'green_card',
    },
    {
      username: 'symown',
      review:
        'I really love this app. I’ve been wanting to learn Chinese for a while and it’s really helping me do this each day.',
      className: 'orange_card',
    },
  ],
  [
    {
      username: 'Kelvin Noel',
      review:
        "maayot is fantastic to anyone who is looking forward to improve their Chinese reading skills. It offers different kinds of stories based on one's interest.",
      className: 'orange_card',
    },
    {
      username: 'Tomas P.',
      review: "It's the first thing I read in the morning before starting my day.",
      className: 'green_card',
    },
  ],
  [
    {
      username: 'Martina Guanta',
      review:
        'Simple, intuitive app, excellent for anyone who wants to increase their vocabulary. Furthermore, the stories are really interesting. I recommend it to anyone who shows interest in this language.',
      className: 'green_card',
    },
    {
      username: 'Debra D.',
      review:
        "Resources I've been using to learn Chinese tend to be quite stale, and rather boring. maayot is great for me to stay up-to-speed.",
      className: 'orange_card',
    },
  ],
]
