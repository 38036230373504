import React from 'react'
import './upgrade-label.css'
import { Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RootReducer } from '../../redux/stores'

const UpgradeLabel = () => {
  const { CTAMessage } = useSelector(({ story }: RootReducer) => story)
  return (
    <div className='upgrade_box_main_div'>
      <div className='left_div'>
        <div className='img_box'>
          <img alt={'Image'} src={'/images/welcome-img.svg'} />
        </div>
        <div className='upgrade_contains'>
          <h5>{CTAMessage?.titleMessage}</h5>
          <p>{CTAMessage?.message}</p>
        </div>
      </div>
      <div className='right_div'>
        <a href={CTAMessage?.link} target='_blank'>
          <Button className='common_btn mob_w_100'>{CTAMessage?.button}</Button>
        </a>
      </div>
    </div>
  )
}

export default UpgradeLabel
