export const {
  NODE_ENV,
  REACT_APP_MAAYOT_API_DEV,
  REACT_APP_MAAYOT_API_PROD,
  REACT_APP_MAAYOT_API_TEMP,
  REACT_APP_PUBLIC_POSTHOG_HOST,
  REACT_APP_PUBLIC_POSTHOG_KEY,
  REACT_APP_GTM_ID,
  REACT_APP_VIDEO_SDK_API_KEY,
  REACT_APP_VIDEO_SDK_API_SECRET,
} = process.env

const MAAYOT_API = NODE_ENV === 'production' ? REACT_APP_MAAYOT_API_PROD : REACT_APP_MAAYOT_API_DEV
export const MEETING_BASE_URL = 'https://api.videosdk.live/v2'

export const headers = {
  'Content-Type': 'application/json',
}
export const postHogOptions = {
  api_host: REACT_APP_PUBLIC_POSTHOG_HOST,
}

export default MAAYOT_API
