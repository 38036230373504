import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import AOS from 'aos'
// import 'aos/dist/aos.css'
import { getLearnAboutMaayotTitle } from '../../redux/actions/onboardingAction'
import { SET_LEARN_ABOUT_ANSWER } from '../../redux/types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { REGEX } from 'utils/regex'
import { RootReducer } from '../../redux/stores'

interface IFormInput {
  referralName?: string
}

const SurveyForm = ({ nextStep }) => {
  const dispatch = useDispatch()
  const { learnAboutType, learnAboutTitle, isLeadUser } = useSelector(({ onboarding }: RootReducer) => onboarding)

  const errorMsgText = learnAboutTitle?.placeholder?.includes('Please')
    ? learnAboutTitle?.placeholder?.replace('Please', '').trim()
    : learnAboutTitle?.placeholder || 'Referral name'
  const schema = yup.object().shape({
    referralName: yup
      .string()
      .min(2, `${errorMsgText} should contain at least 2 characters`)
      .max(50, `${errorMsgText} cannot exceed 50 characters`)
      .matches(REGEX.referralName, `${errorMsgText} can only contain letters, numbers, and spaces`)
      .optional(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ mode: 'onSubmit', resolver: yupResolver(schema) })

  const handle = {
    submit: (data) => {
      dispatch({ type: SET_LEARN_ABOUT_ANSWER, payload: data?.referralName })

      setTimeout(() => {
        handle.navigate()
      }, 100)
    },
    navigate: () => {
      nextStep(isLeadUser ? 7 : 4)
    },
    getLearnAboutMaayotTitle: async (source: string) => {
      if (source) {
        await getLearnAboutMaayotTitle({ source })
      }
    },
  }

  useEffect(() => {
    handle.getLearnAboutMaayotTitle(learnAboutType)
  }, [learnAboutType])

  // useEffect(() => {
  //   AOS.init()
  // }, [])

  return (
    <>
      <div className='w-container'>
        <div className='choose-lunguage-main-div overflow-hidden-mob'>
          <div className='common-title-sec'>
            <h2 className='screen-sub-title'>{learnAboutTitle?.question}</h2>
          </div>

          {learnAboutTitle?.placeholder && (
            <>
              <div className='login-field-main-div' style={{ marginTop: '10px' }}>
                <div>
                  {/* data-aos='fade-down' data-aos-duration='1000' data-aos-delay='1000' data-aos-once='true' */}
                  <form className='form-2' onSubmit={handleSubmit(handle.submit)}>
                    <div className='form-group'>
                      <input
                        type='text'
                        className={`form-input-2 w-input ${errors.referralName ? 'error' : ''}`}
                        {...register('referralName')}
                        placeholder={learnAboutTitle?.placeholder || 'Referral name'}
                      />
                      {errors.referralName && <p className='error-msg'>{errors.referralName.message}</p>}
                    </div>

                    <div className='text-center'>
                      <button
                        className='common-btn-cls green-btn-class'
                        type='submit'
                        style={{ marginTop: '10px' }}
                        data-aos='fade-In'
                        // data-aos-duration='1700'
                        // data-aos-easing='linear'
                        // data-aos-once='true'
                        // data-aos-offset='0'
                      >
                        Next
                      </button>
                    </div>
                    <div className='bottom-section'>
                      <p
                        className='links-text second'
                        style={{ textDecoration: 'underline', fontWeight: '700' }}
                        onClick={() => handle.navigate()}
                      >
                        skip
                      </p>
                    </div>
                  </form>
                </div>
              </div>
              {/* <div className='text-center'>
                <p
                  className='links-text'
                  style={{ textDecoration: 'underline', fontWeight: '700' }}
                  onClick={() => handle.navigate()}
                  // data-aos='fade-In'
                  // data-aos-duration='2000'
                  // data-aos-easing='linear'
                  // data-aos-once='true'
                >
                  Skip to result
                </p>
              </div> */}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default SurveyForm
