import Translate from 'containers/popup/Translate'
import React, { useEffect, useRef, useState } from 'react'
import { translate } from '../../redux/actions/storyAction'
import { AddWordToHackChinese, handleAddWordToSkritter } from '../../redux/actions/userActions'
import { characterConst, converterType, wordType } from 'shared/constant'
import QuizOptions from './QuizOptions'
import QuizQuestion from './QuizQuestion'

interface IQuizResultProps {
  quizResult?: any
  quizDetail: any
  selected: any
  story: any
  user_auth_token: any
  skritter_token: any
  chineseWordFontSize: any
}

const checkIcon = <i className='fa fa-check color-primary' />

const QuizResult: React.FC<IQuizResultProps> = (props: any) => {
  const { quizResult, selected, quizDetail, story, chineseWordFontSize } = props
  const { selectedAnswer, rightAnswer } = selected
  const sumSelected = quizResult.options.reduce((sum, curr) => sum + curr)
  const percentOption = quizResult.options.map((val) => Math.round((val / sumSelected) * 100))
  const isAnswer = (val) => val == selectedAnswer
  const isRight = (val) => val == rightAnswer

  const [showPinYin, setShowPinYin] = useState(false)
  const contentStoryRef = useRef(document.createElement('div'))
  const [transVisible, setTransVisible] = useState(false)
  const [transData, setTransData] = useState({})
  const [activeIndex, setActiveIndex] = useState('-1')
  const popupRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    document.addEventListener('scroll', onTranslateClose)
    document.addEventListener('click', onDocumentClick)
    return () => {
      document.removeEventListener('scroll', onTranslateClose)
      document.removeEventListener('click', onDocumentClick)
    }
  }, [contentStoryRef, props.questionDetail])

  const setTranslate = (word: string, posTop: number, posLeft: number, data: any) => {
    setTransVisible(true)
    const type = story?.oldWords?.includes(word)
      ? wordType.OldWord
      : story?.newWord1 === word || story?.newWord2 === word
        ? wordType.NewWord
        : wordType.Word
    // const type = wordType.OldWord
    setTransData({
      type,
      top: posTop,
      left: posLeft,
      word,
      definition: data.pinyin,
      example1: data?.english,
      example2: '',
      level: 'HSK 5',
    })
  }

  const handleTranslate = (event) => {
    if (
      (event.type === 'mouseover' || event.type === 'click') &&
      event.target.innerText &&
      event.target.innerText.length > 0
    ) {
      trans(event.target.innerText, event.clientY, event.clientX)
    }
  }

  const trans = (word: string, posTop: number, posLeft: number) => {
    if (/^[&.-”“，、；？@!#$%^&*()]+$/.test(word) || word.trim() === '。') {
      setTransVisible(false)
      return
    }

    const wordTranslate = quizDetail?.allquizopContent[0]?.filter(
      (iword) =>
        (globalThis.characterPreference === characterConst.traditional
          ? converterType.converterTraditional(iword.word)
          : converterType.converterSimplified(iword.word)) === word,
    )
    // if (wordTranslate && wordTranslate.length > 0) {
    //   setTranslate(word, posTop, posLeft, wordTranslate[0])
    // }
    if (wordTranslate && wordTranslate.length > 0) {
      setTranslate(word, posTop, posLeft, wordTranslate[0])
      if (props?.user_auth_token) {
        AddWordToHackChinese(wordTranslate[0], props?.user_auth_token)
      }
      if (props?.skritter_token) {
        handleAddWordToSkritter({
          word,
          translate: wordTranslate[0],
          title: props?.story?.storyTitleContent,
          skritter_token: props?.skritter_token,
        })
      }
    } else {
      translate(word)
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          if (data && data.length > 0) {
            setTranslate(word, posTop, posLeft, data[0])
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const onTranslateClose = () => {
    setTransVisible(false)
  }
  const onDocumentClick = (e) => {
    if (!popupRef?.current?.contains(e.target)) {
      setTransVisible(false)
    }
  }

  const handleClickWord = (event, key) => {
    handleTranslate(event)
    setActiveIndex(activeIndex === key ? `-1` : key)
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <div className='w-row section-story-content'>
      <div className='w-col w-col-9 content-left'>
        <div className='session-title'>
          <span>
            {globalThis.characterPreference === characterConst.traditional ? (
              <QuizQuestion
                questionDetail={quizDetail.quizContent}
                showPinYin={showPinYin}
                story={story}
                handleClickWord={handleClickWord}
                activeIndex={activeIndex}
                chineseWordFontSize={chineseWordFontSize}
              />
            ) : (
              <QuizQuestion
                questionDetail={quizDetail.quizContent}
                showPinYin={showPinYin}
                story={story}
                activeIndex={activeIndex}
                handleClickWord={handleClickWord}
                chineseWordFontSize={chineseWordFontSize}
              />
            )}
          </span>
        </div>
        <div className='quiz-answers'>
          <div className='form-quiz-answers'>
            {(quizDetail.options || []).map((option, iterator) => {
              const _isAnswer = isAnswer(option.value)
              const _isRight = isRight(option.value)
              let _progressClass = ''
              if (_isAnswer) {
                _progressClass = _isRight ? `progress-percent-right` : `progress-percent-wrong`
              }
              return (
                <div className='form-item' key={iterator}>
                  <div className='m-flx'>
                    <div className='m-radio'>
                      {_isAnswer ? (
                        _isRight ? (
                          <i className='fa fa-check-circle color-primary' />
                        ) : (
                          <i className='fa fa-times-circle color-error' />
                        )
                      ) : (
                        <i className='fa fa-circle-o' />
                      )}
                    </div>
                    <span
                      style={{
                        fontSize: chineseWordFontSize?.newWordsExample,
                        lineHeight: '1.5',
                        letterSpacing: '1.5px',
                      }}
                    >
                      {globalThis.characterPreference === characterConst.traditional ? (
                        <QuizOptions
                          iterator={iterator}
                          quizDetail={quizDetail.optionsWithContent[iterator]}
                          showPinYin={showPinYin}
                          story={story}
                          activeIndex={activeIndex}
                          handleClickWord={handleClickWord}
                        />
                      ) : (
                        <QuizOptions
                          iterator={iterator}
                          quizDetail={quizDetail.optionsWithContent[iterator]}
                          showPinYin={showPinYin}
                          story={story}
                          activeIndex={activeIndex}
                          handleClickWord={handleClickWord}
                        />
                      )}
                    </span>
                    {_isAnswer && (
                      <span className={`quiz-result-answer color-${_isRight ? 'primary' : 'default'}`}>
                        Your Answer {_isRight && checkIcon}
                      </span>
                    )}
                    {!_isAnswer && _isRight && (
                      <span className={`quiz-result-answer color-default`}>Correct Answer {_isRight && checkIcon}</span>
                    )}
                  </div>
                  <div className={'answer-progress w-row m-al-c'}>
                    <div className={'w-col w-col-6  w-col-small-12 progress-full '}>
                      <div
                        className={`progress-percent ${_progressClass}`}
                        style={{ width: `${percentOption[iterator]}%` }}
                      />
                    </div>
                    <div className={'w-col w-col-6 w-col-small-12 m-al-l'}>
                      <span className={'progress-pos-text'}>{percentOption[iterator]}% of learners answered this</span>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <Translate ref={popupRef} visible={transVisible} data={transData} onClose={onTranslateClose} />
    </div>
  )
}

export default React.memo(QuizResult)
