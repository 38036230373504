import React, { useEffect } from 'react'
import { ACCOUNT } from '../../routes/routeConstant'
import Loader from '../../containers/loader/loader'
import { useNavigate } from 'react-router-dom'

const SuccessPaid: React.FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(ACCOUNT)
  }, [])
  return <Loader />
}
export default SuccessPaid
