import moment from 'moment'
import React, { useState } from 'react'
import Countdown from 'react-countdown'
import { countDownLabel, countDownCheckTime, milisInDay, countDownType, milisInWeek } from '../../shared/constant'
import { momentToMilis } from '../../utils/utils'
import { useSelector } from 'react-redux'
import { getCurrentStories } from '../../redux/actions/storyAction'
import { RootReducer } from '../../redux/stores'

interface ICountdownProps {
  // level: string;
  type?: countDownType
  color?: string
}

const CountDown: React.FC<ICountdownProps> = (props: ICountdownProps) => {
  // check time countdown with GMT +0 and local timezone
  const { credentials, membership } = useSelector(({ user }: RootReducer) => user)
  const memberId = credentials?.memberId
  const level = credentials?.customFields?.level
  const currentDay = moment.utc()
  let timeCDMilis = milisInDay - momentToMilis(currentDay)
  if (props.type === countDownType.Week && currentDay.weekday() != 7) {
    timeCDMilis = milisInWeek - currentDay.weekday() * milisInDay - momentToMilis(currentDay)
  }
  const [stopTimer, setStopTimer] = useState(true)
  let typeLabel = !props.color && countDownLabel.Green

  const handle = {
    onComplete: () => {
      if (stopTimer) {
        setStopTimer(false)
        getCurrentStories({ memberId, level, plan: membership?.name })
      }
    },
  }

  const renderer = ({
    // days,
    hours,
    minutes,
    // seconds,
    formatted,
  }: {
    days: any
    hours: any
    minutes: any
    seconds: any
    formatted: any
    completed: boolean
  }) => {
    typeLabel = !props.color && countDownLabel.Green
    // Check time to render countdown color
    if (hours * 60 + minutes <= countDownCheckTime.Red) {
      typeLabel = !props.color && countDownLabel.Red
    } else if (hours * 60 + minutes <= countDownCheckTime.Orange) {
      typeLabel = !props.color && countDownLabel.Orange
    }

    // Render a countdown
    return (
      <div className='countdown'>
        {props.type === countDownType.Week && (
          <>
            <div className='w-row block-countdown'>
              <div className='w-col count-down-text level-week'>{formatted?.days}</div>
              <div className='level-week-text'>Day</div>
            </div>
            &nbsp;
            <div className='w-row block-countdown'>
              <div className='w-col count-down-text level-week'>{formatted?.hours}</div>
              <div className='level-week-text'>Hour</div>
            </div>
            &nbsp;
            <div className='w-row block-countdown'>
              <div className='w-col count-down-text level-week'>{formatted?.minutes}</div>
              <div className='level-week-text'>Min</div>
            </div>
            &nbsp;
            <div className='w-row block-countdown'>
              <div className='w-col count-down-text level-week'>{formatted?.seconds}</div>
              <div className='level-week-text'>Sec</div>
            </div>
          </>
        )}
        {props.type === countDownType.Day && (
          <>
            <div className={`count-down-text level-${typeLabel}`}>{formatted?.hours}</div>
            &nbsp;
            <div className={`text-dots-mark text-${typeLabel}`}>:</div>&nbsp;
            <div className={`count-down-text level-${typeLabel}`}>{formatted?.minutes}</div>
            &nbsp;
            <div className={`text-dots-mark text-${typeLabel}`}>:</div>&nbsp;
            <div className={`count-down-text level-${typeLabel}`}>{formatted?.seconds}</div>
          </>
        )}
      </div>
    )
  }

  return <Countdown date={Date.now() + timeCDMilis} renderer={renderer} onComplete={handle.onComplete} />
}

export default CountDown
