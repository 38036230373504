import {
  SET_CORRECTION_ANSWER,
  SET_CORRECTION_COUNT,
  SET_CORRECTION_DATA,
  SET_CORRECTION_ERRORS,
  SET_CORRECTION_QUESTION,
  SET_CORRECTION_STORY,
} from '../types'
import MAAYOT_API, { headers } from './config'

export const getOldWord = async () => {
  return await fetch(`${MAAYOT_API}words/past-new-words`, {
    method: 'GET',
    headers,
  }).then((resData) => resData.json())
}

export const setCorrections = (corrections: any) => (dispatch: any) => {
  dispatch({
    type: SET_CORRECTION_DATA,
    payload: corrections,
  })
}

export const GetStudentCorrection = (perPage: string, page: string, memberId: string) => async (dispatch: any) => {
  return new Promise((resolve, reject) => {
    try {
      // fetch(`${MAAYOT_API}story-correction/closed-corrections?memberId=${memberId}`, {
      fetch(`${MAAYOT_API}story-correction/closed-corrections?par_page=${perPage}&page=${page}&memberId=${memberId}`, {
        method: 'GET',
        headers,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data && Object.keys(data) && Object.keys(data).length > 0) {
            const ansData = data.answers.map((data) => {
              return data.data
            })
            dispatch({
              type: SET_CORRECTION_ANSWER,
              payload: data.answers,
            })
            dispatch({
              type: SET_CORRECTION_COUNT,
              payload: data.count,
            })
            dispatch({
              type: SET_CORRECTION_DATA,
              payload: ansData,
            })
            // resolve(data.answers)
            resolve({
              Answers: data.answers,
              Data: ansData,
            })
          } else {
            dispatch({
              type: SET_CORRECTION_ERRORS,
              payload: 'Error',
            })
            reject('Error')
          }
        })
    } catch (error) {
      console.log('error')
    }
  })
}

export const getQuestion = (id: string, level: string, memberId: string) => (dispatch: any) => {
  return new Promise<void>((resolve, reject) => {
    fetch(`${MAAYOT_API}question/?storyId=${id}&level=${level?.toLowerCase() || ''}&memberId=${memberId}`, {
      method: 'GET',
      headers,
    })
      .then((resData) => resData.json())
      .then((data) => {
        if (data && !data.status && !data.statusCode && Object.keys(data) && Object.keys(data).length > 0) {
          dispatch({
            type: SET_CORRECTION_QUESTION,
            payload: {
              question: data.question,
              questionContent: data.questionContent,
            },
          })
          resolve()
        } else {
          dispatch({
            type: SET_CORRECTION_ERRORS,
            payload: 'Error',
          })
          reject()
        }
      })
  })
}

export const getStory = (id: string, level: string, memberId: number) => async (dispatch: any) => {
  if (!id || !level || !memberId) {
    return Promise.reject('Invalid params')
  }

  await fetch(`${MAAYOT_API}prismic/findStory?storyId=${id}&level=${level.toLowerCase()}&memberId=${memberId}`, {
    method: 'GET',
    headers,
  })
    .then((resData) => {
      return resData.json()
    })
    .then((dataStory) => {
      if (dataStory && JSON.stringify(dataStory) !== '{}') {
        // return Promise.all([dataStory, getLastStep(dataStory.id, memberId, level), getOldWord()])
        return Promise.all([dataStory, getOldWord()])
        // return Promise.resolve(dataStory)
      } else {
        dispatch({
          type: SET_CORRECTION_ERRORS,
          payload: 'Story not found',
        })
        return Promise.reject("'Story not found',")
      }
    })
    .then((res) => {
      // const [dataStory, lastStep, oldWordRes] = res
      const [dataStory, oldWordRes] = res
      // const dataStory = res
      dataStory.arSentencesWord11 = dataStory?.arSentencesWord11 ? dataStory.arSentencesWord11 : []
      dataStory.arSentencesWord12 = dataStory?.arSentencesWord12 ? dataStory.arSentencesWord12 : []
      dataStory.arSentencesWord21 = dataStory?.arSentencesWord21 ? dataStory.arSentencesWord21 : []
      dataStory.arSentencesWord22 = dataStory?.arSentencesWord22 ? dataStory.arSentencesWord22 : []

      dataStory.storyContent = dataStory.arContentWithTags
      dataStory.arWordSen11 = dataStory.arSentencesWord11.map((word) => word.word)
      dataStory.arWordSen12 = dataStory.arSentencesWord12.map((word) => word.word)
      dataStory.arWordSen21 = dataStory.arSentencesWord21.map((word) => word.word)
      dataStory.arWordSen22 = dataStory.arSentencesWord22.map((word) => word.word)
      dataStory.arContent = [
        ...dataStory.arContent,
        ...dataStory.arSentencesWord11,
        ...dataStory.arSentencesWord12,
        ...dataStory.arSentencesWord21,
        ...dataStory.arSentencesWord22,
      ]
      let oldWords
      if (oldWordRes?.length) {
        oldWords = []
        oldWordRes.forEach((wordObj) => {
          const { data = {} } = wordObj || {}
          const { word, level: objLevel } = data || {}
          if (objLevel?.toLowerCase() == level.toLowerCase()) {
            oldWords.push(word)
          }
        })
      }
      dispatch({
        type: SET_CORRECTION_STORY,
        payload: { ...dataStory, oldWords },
      })
      // dispatch({
      //   type: SET_LAST_STEP,
      //   payload: lastStep?.data?.[0]?.data?.step,
      // })
      return Promise.resolve({ ...dataStory, oldWords })
    })
    .catch((err) => {
      console.error(err)
      dispatch({
        type: SET_CORRECTION_ERRORS,
        payload: 'Story not found',
      })
      dispatch({
        type: SET_CORRECTION_STORY,
        payload: null,
      })
      return Promise.reject(err)
    })
}
