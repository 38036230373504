import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  postHackChineseTokenForUser,
  removeHackChineseTokenForUser,
  removeSkritterTokenForUser,
} from '../../redux/actions/userActions'
import {
  ACCOUNT_REMOVE_HACK_CHINESE,
  ACCOUNT_REMOVE_SKRITTER,
  ACCOUNT_SET_HACK_CHINESE,
  ACCOUNT_SET_SKRITTER,
} from '../../redux/types'
import { getSkritterToken, handleSkritterLogin } from '../../services/skritter'

const { REACT_APP_HACK_CHINESE_APP_KEY } = process.env

interface IntegrationProps {
  credentials?: any
}

const Integrations: React.FC<IntegrationProps> = (props: any) => {
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { user_auth_token } = props

  const { memberId } = props.credentials
  //Authorization: `Bearer ${MemberStack.getToken()}`,

  const handleFormSubmit = async (e) => {
    e.preventDefault()

    const data = {
      user_auth_token: token,
    }
    try {
      const headers = {
        Authorization: `${REACT_APP_HACK_CHINESE_APP_KEY}`,
        'Content-Type': 'application/json',
      }
      const response = await axios.post('https://www.hackchinese.com/establish_link_for_user', data, {
        headers: headers,
      })
      if (response?.status === 200) {
        const result = await postHackChineseTokenForUser(memberId, token)
        if (result?.status === 200) {
          dispatch({ type: ACCOUNT_SET_HACK_CHINESE, payload: { token } })
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const [isOpen, setIsOpen] = useState<any>({
    show: false,
    skritter: false,
  })

  const toggle = (name) => {
    setIsOpen({ ...isOpen, [name]: !isOpen[name] })
  }

  const removeHackChineseToken = async (e) => {
    e.preventDefault()
    const response = await removeHackChineseTokenForUser(memberId)
    if (response?.status === 200) {
      dispatch({ type: ACCOUNT_REMOVE_HACK_CHINESE })
    }
  }

  const handleSkritterConnect = () => {
    if (props?.skritter_token) {
      removeSkritterTokenForUser(memberId).then(() => {
        dispatch({ type: ACCOUNT_REMOVE_SKRITTER, payload: {} })
        localStorage.removeItem('skritterData')
      })
    } else {
      handleSkritterLogin({ memberId })
    }
  }

  useEffect(() => {
    window.addEventListener('storage', () => {
      // this event will fire whenever the local storage is updated
      const newToken = getSkritterToken()
      if (props?.skritter_token !== newToken.skritter_token) {
        dispatch({ type: ACCOUNT_SET_SKRITTER, payload: { token: newToken.skritter_token, skritter: newToken } })
      }
    })
    return () => {
      window.removeEventListener('storage', () => {
        //
      })
    }
  }, [])

  return (
    <div className='level-right m-b-white'>
      <h4 className='m-h4 m-gray-1'>Integrations</h4>
      <div className='correction-item'>
        <div className='w-row  w-d-flex section-story-content mb-20 '>
          <div className='w-col-12 w-col-medium-12 w-col-small-12 w-col-tiny-12 box-padding border-full border-radius-5 mb-15'>
            <div className='integration_box'>
              <div className='integration_box_item w-d-flex m-al-c m-sp-bw'>
                <span className='box-title'>Hack Chinese</span>
                <div className='w-d-flex m-al-c'>
                  <button
                    className={`integration_btn color-white m-b-primary-2 border-radius-5 
                    ${user_auth_token ? ' connected-btn ' : ' not-connected '}
                    `}
                    onClick={() => toggle('show')}
                  >
                    {user_auth_token ? 'Connected' : 'Not Connected'}
                  </button>
                  <span className='ml-10'>
                    <i
                      onClick={() => toggle('show')}
                      className={`m-cursor-pointer ${isOpen.show ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}`}
                    ></i>
                  </span>
                </div>
              </div>
              {isOpen.show && (
                <div className='story-content intgration_content_padding border-top section-story'>
                  <h4 className='m-gray-1 intgration_content_title'>Sync with Hack Chinese</h4>
                  <p className='item-title m-gray-1'>
                    Click on any characters in the story you're not familiar with and have them automatically synced to
                    your maayot list on Hack Chinese.
                  </p>
                  {user_auth_token ? (
                    <>
                      <p className='item-title m-gray-1'>
                        Congratulations, You have successfully linked Hack Chinese with maayot
                      </p>
                      <div className='token-points-div'>
                        <div className='membership-feature-item'>
                          <span className='m-sm-text'>
                            Click on the below Unlink button to unsync your Hack Chinese account
                          </span>
                        </div>
                        <div className='membership-feature-item'>
                          <button className='btn button-4 w-inline-block' onClick={removeHackChineseToken}>
                            Unlink
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <p className='item-title m-gray-1'>Follow these steps:</p>
                      <div className='token-points-div'>
                        <div className='membership-feature-item'>
                          <label className='num-text'>1</label>
                          <span className='m-sm-text'>
                            Go to{' '}
                            <a href='https://www.hackchinese.com/sync-with-maayot' target='_blank'>
                              Hack Chinese
                            </a>{' '}
                            and find your token.
                          </span>
                        </div>
                        <div className='membership-feature-item'>
                          <label className='num-text'>2</label>
                          <span className='m-sm-text'>Enter it below and select “Link”.</span>
                        </div>
                        <div className='membership-feature-item'>
                          <label className='num-text'>3</label>
                          <span className='m-sm-text'>Your words are now auto-magically saved!</span>
                        </div>
                      </div>
                      <form onSubmit={handleFormSubmit}>
                        <div className='token-form-div'>
                          <input
                            pattern='([^:]{16})'
                            type='text'
                            className='input-custom-filed'
                            name='token'
                            id='token'
                            placeholder='Enter your Hack Chinese token...'
                            onChange={(e) => {
                              setToken(e.target.value)
                            }}
                          />
                          <button className='btn button-4 w-inline-block' type='submit'>
                            Link
                          </button>
                        </div>
                      </form>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='w-col-12 w-col-medium-12 w-col-small-12 w-col-tiny-12 box-padding border-full border-radius-5 mb-15'>
            <div className='integration_box'>
              <div className='integration_box_item w-d-flex m-al-c m-sp-bw'>
                <span className='box-title'>Skritter</span>
                <div className='w-d-flex m-al-c'>
                  <button
                    className={`integration_btn color-white m-b-primary-2 border-radius-5 
                    ${props?.skritter_token ? ' connected-btn ' : ' not-connected '}
                    `}
                    onClick={() => toggle('skritter')}
                  >
                    {props?.skritter_token ? 'Connected' : 'Not Connected'}
                  </button>
                  <span className='ml-10'>
                    <i
                      onClick={() => toggle('skritter')}
                      className={`m-cursor-pointer ${isOpen.skritter ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}`}
                    ></i>
                  </span>
                </div>
              </div>
              {isOpen.skritter && (
                <div className='story-content intgration_content_padding border-top section-story'>
                  <h4 className='m-gray-1 intgration_content_title'> Sync with Skritter </h4>
                  <p className='mb-20'>
                    Link your{' '}
                    <a href='https://skritter.com?ref=yjy1y2e' target='_blank'>
                      Skritter
                    </a>{' '}
                    account below and any words you're not familiar with in the maayot story and click on will be
                    automatically synced to your Skritter list.
                  </p>
                  <button
                    onClick={handleSkritterConnect}
                    className='sync_btn bg_transperent green_color border_green border-radius-5'
                  >
                    {props?.skritter_token ? 'Unlink' : 'Sync'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  user_auth_token: state.user.user_auth_token,
  skritter_token: state.user.skritter_token,
  credentials: state.user.credentials,
})

export default connect(mapStateToProps)(Integrations)
