import axios, { AxiosError, InternalAxiosRequestConfig, AxiosResponse } from 'axios'
import MAAYOT_API, { headers } from '../redux/actions/config'
import { logoutUser } from '../redux/actions/userActions'

declare const MemberStack: any

const axiosInstance = axios.create({
  baseURL: MAAYOT_API,
  headers,
})

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = MemberStack.getToken()
    if (token) {
      config.headers.set('Authorization', `Bearer ${token}`)
    }
    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response) {
      if (error.response.status === 401) {
        logoutUser()
      }
    }
    return Promise.reject(error)
  },
)

export default axiosInstance
