import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import Upgrade from './Upgrade'
import MyAccount from './MyAccount'
import MemberShip from './MemberShip'
import Streaks from './Streaks'
import Level from './Level'
import { isEnableStreaking } from '../../utils/services'
import { accountTab } from '../../shared/constant'
import Character from './Character'
import Integrations from './Integrations'
const UserIcon = <i className='fa fa-user-o' />
const CardIcon = <i className='fa fa-credit-card' />
const StarIcon = <i className='fa fa-star-o' />
const CharacterIcon = <img className={'fa'} src={'/images/icons/CharacterMenu.svg'} alt='' />
const StreakIcon = <img className={'fa'} src={'/images/icons/icon-streak.png'} alt='' />
const IntegrationIcon = <img className={'fa'} src={'/images/icons/Integration.svg'} alt='' />

const MembersAccount: React.FC = ({ membership, credentials }: any) => {
  const location = useLocation()

  const { name, id } = membership || {}
  const isEnableStreak = useMemo(() => isEnableStreaking(name, id), [name, id])

  const [tab, setTab] = useState(
    location.hash === '' && isEnableStreak
      ? accountTab.Streak
      : location.hash === '#integrations'
        ? accountTab.Integrations
        : location.hash === '#plan'
          ? accountTab.Plan
          : location.hash === '#level'
            ? accountTab.Level
            : location.hash === '#character'
              ? accountTab.Character
              : location.hash === '#streaks'
                ? accountTab.Streak
                : accountTab.MyAccount,
  )

  useEffect(() => {
    if (location.hash === '' && isEnableStreak) {
      setTab(accountTab.Streak)
    } else {
      if (location.hash === '#integrations') {
        setTab(accountTab.Integrations)
      } else if (location.hash === '#plan') {
        setTab(accountTab.Plan)
      } else if (location.hash === '#level') {
        setTab(accountTab.Level)
      } else if (location.hash === '#character') {
        setTab(accountTab.Character)
      } else if (location.hash === '#streaks') {
        setTab(accountTab.Streak)
      } else {
        setTab(accountTab.MyAccount)
      }
    }
  }, [membership?.name])

  // Get Plan Data
  useEffect(() => {
    if (location?.state) {
      changeTab(location?.state || accountTab.MyAccount)
    }
  }, [])

  const changeTab = (tabId) => {
    setTab(tabId)
  }
  const onSelect = (e) => {
    changeTab(parseInt(e.target.value))
  }
  return (
    <>
      <div className={'account-sidebar-mobile'}>
        <select onChange={onSelect} defaultValue={location.hash === '' && isEnableStreak ? accountTab.Streak : tab}>
          {isEnableStreak && <option value={accountTab.Streak}>Streaks</option>}
          <option value={accountTab.MyAccount}>My Account</option>
          <option value={accountTab.Plan}>My Plan</option>
          <option value={accountTab.Level}>Level</option>
          <option value={accountTab.Character}>Character</option>
          <option value={accountTab.Integrations}>Integrations</option>
        </select>
      </div>
      <div className='section-2'>
        <Upgrade membership={membership} credentials={credentials} />
      </div>
      <div className='main-session w-container m-b-primary-3'>
        <div className='w-row m-flx'>
          <div className='w-col w-col-4 content-left account-sidebar'>
            <ul className={'sidebar-list color-white'}>
              {isEnableStreak && (
                <Link to='#streaks'>
                  <li
                    className={tab === accountTab.Streak ? 'active' : ''}
                    onClick={() => changeTab(accountTab.Streak)}
                  >
                    <div>
                      {StreakIcon}
                      <span style={{ textDecoration: 'none' }}>Streaks</span>
                    </div>
                  </li>
                </Link>
              )}
              <Link to='#account'>
                <li
                  className={tab === accountTab.MyAccount ? 'active' : ''}
                  onClick={() => changeTab(accountTab.MyAccount)}
                >
                  <div>
                    {UserIcon}
                    <span>My Account</span>
                  </div>
                </li>
              </Link>
              <Link to='#plan'>
                <li className={tab === accountTab.Plan ? 'active' : ''} onClick={() => changeTab(accountTab.Plan)}>
                  <div>
                    {CardIcon}
                    <span>My Plan</span>
                  </div>
                </li>
              </Link>
              {/* <Link to='#level'>
                <li className={tab === accountTab.Level ? 'active' : ''} onClick={() => changeTab(accountTab.Level)}>
                  <div>
                    {StarIcon}
                    <span>Level</span>
                  </div>
                </li>
              </Link> */}
              <Link to='#character'>
                <li
                  className={tab === accountTab.Character ? 'active' : ''}
                  onClick={() => changeTab(accountTab.Character)}
                >
                  <div>
                    {CharacterIcon}
                    <span>Character</span>
                  </div>
                </li>
              </Link>
              <Link to='#integrations'>
                <li
                  className={tab === accountTab.Integrations ? 'active' : ''}
                  onClick={() => changeTab(accountTab.Integrations)}
                >
                  <div>
                    {IntegrationIcon}
                    <span>Integrations</span>
                  </div>
                </li>
              </Link>
            </ul>
          </div>
          {isEnableStreak && (
            <div className={`w-col w-col-8  right-content ${tab === accountTab.Streak ? ' active' : ' inactive'}`}>
              <Streaks />
            </div>
          )}
          <div className={`w-col w-col-8  right-content ${tab === accountTab.MyAccount ? ' active' : ' inactive'}`}>
            <MyAccount />
          </div>
          <div className={`w-col w-col-8  right-content ${tab === accountTab.Plan ? ' active' : ' inactive'}`}>
            <MemberShip membership={membership} credentials={credentials} />
          </div>
          {/* <div className={`w-col w-col-8  right-content ${tab === accountTab.Level ? ' active' : 'inactive'}`}>
            <Level />
          </div> */}
          <div className={`w-col w-col-8  right-content ${tab === accountTab.Character ? ' active' : 'inactive'}`}>
            <Character />
          </div>
          <div className={`w-col w-col-8  right-content ${tab === accountTab.Integrations ? ' active' : 'inactive'}`}>
            <Integrations />
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  credentials: state.user.credentials,
  membership: state.user.membership,
})
export default connect(mapStateToProps)(MembersAccount)
