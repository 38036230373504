import React, { useEffect, useState } from 'react'
import './card.css'
import moment from 'moment'
import { characterConst, converterType, countDownType } from 'shared/constant'
import CountDown from 'containers/countdown/CountDown'
import SkeletonLoader from 'components/skeleton-loader'
import { checkStorySubmission, getCurrentStories, getLastStep, getOldWord } from '../../redux/actions/storyAction'
import { useDispatch, useSelector } from 'react-redux'
import Loader from 'containers/loader/loader'
import { CLEAR_QUESTION_ANSWER_LIST, RESET_QUIZ, SET_LAST_STEP, SET_STORY, SET_STORY_LOADING } from '../../redux/types'
import { INTRODUCTION } from 'routes/routeConstant'
import { useNavigate } from 'react-router-dom'
import { RootReducer } from '../../redux/stores'

const towerIcon = (num) => {
  if (num > 20) {
    return <img alt={'Image'} src={'/images/tower-no-icon.svg'} />
  } else if (num > 10 && num <= 20) {
    return <img alt={'Image'} src={'/images/tower-one-icon.svg'} />
  } else if (num > 5 && num <= 10) {
    return <img alt={'Image'} src={'/images/tower-two-icon.svg'} />
  } else {
    return <img alt={'Image'} src={'/images/tower-fill-icon.svg'} />
  }
}

const CommonCard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { credentials, membership } = useSelector(({ user }: RootReducer) => user)
  const { storiesListLoader, storiesList, CTAMessage } = useSelector(({ story }: RootReducer) => story)
  const memberId = credentials?.memberId
  const level = credentials?.customFields?.level
  const [storyDataLoader, setStoryDataLoader] = useState('')
  const [countDownCheckTimer, setCountDownTimer] = useState(true)

  useEffect(() => {
    if (memberId && level) {
      setCountDownTimer(false)
      getCurrentStories({ memberId, level, plan: membership?.name }).then(({ data }) => {
        if (CTAMessage?.button !== 'Get started') {
          const storyIds = data?.map(({ id }) => id)
          checkStorySubmission({ storyIds, memberId })
          setCountDownTimer(true)
        }
      })
      return
    }
  }, [memberId, level])

  const handleSetStory = async (dataStory) => {
    setStoryDataLoader(dataStory?.id)
    const lastStep = getLastStep(dataStory.id, memberId, dataStory?.level)
    const oldWordRes: any = await getOldWord()
    let finalStoryIntroduction = dataStory.storyIntroduction?.toString() || ''
    const re = new RegExp(String.fromCharCode(160), 'g')
    finalStoryIntroduction = finalStoryIntroduction?.replace(re, ' ')
    dataStory.englishContent = dataStory.englishContent
    dataStory.storyContent = dataStory.arContentWithTags
    dataStory.storyIntroduction = finalStoryIntroduction
    dataStory.arSentencesWord11 = dataStory?.arSentencesWord11 ? dataStory.arSentencesWord11 : []
    dataStory.arSentencesWord12 = dataStory?.arSentencesWord12 ? dataStory.arSentencesWord12 : []
    dataStory.arSentencesWord21 = dataStory?.arSentencesWord21 ? dataStory.arSentencesWord21 : []
    dataStory.arSentencesWord22 = dataStory?.arSentencesWord22 ? dataStory.arSentencesWord22 : []
    dataStory.arContent = [
      ...dataStory?.arContent,
      ...dataStory.arSentencesWord11,
      ...dataStory.arSentencesWord12,
      ...dataStory.arSentencesWord21,
      ...dataStory.arSentencesWord22,
    ]

    let oldWords
    if (oldWordRes?.length) {
      oldWords = []
      oldWordRes.forEach((wordObj) => {
        const { data = {} } = wordObj || {}
        const { word, level } = data || {}
        if (level?.toLowerCase() == dataStory?.level.toLowerCase()) {
          oldWords.push(word)
        }
      })
    }
    dispatch({
      type: SET_STORY,
      payload: dataStory?.is_latest ? { ...dataStory, oldWords } : { ...dataStory },
    })
    dispatch({
      type: SET_LAST_STEP,
      payload: lastStep?.data?.[0]?.data?.step,
    })
    dispatch({
      type: SET_STORY_LOADING,
      payload: { storyLoading: false },
    })
    setStoryDataLoader('')
    dispatch({ type: RESET_QUIZ })
    dispatch({ type: CLEAR_QUESTION_ANSWER_LIST })
    navigate(INTRODUCTION)
  }

  return (
    <div className='card_sec_main_div'>
      <h4 className='main_title_text'>
        {moment().format('MMM Do YYYY')}{' '}
        {countDownCheckTimer && (
          <span>
            <CountDown type={countDownType.Day} color={'none'} /> to story refresh
          </span>
        )}
      </h4>

      {storiesListLoader ? (
        <SkeletonLoader />
      ) : (
        storiesList?.map((story) => (
          <div className={story?.is_latest ? 'card_box_div small_card_div loader_div' : 'card_box_div'} key={story?.id}>
            {storyDataLoader && storyDataLoader === story?.id ? (
              <Loader />
            ) : (
              <>
                {story?.is_recommended ? (
                  <>
                    <div className='left_sec' onClick={() => handleSetStory(story)}>
                      <img
                        className={story?.is_read ? 'left_sec_img gary' : 'left_sec_img'}
                        alt={'Image'}
                        src={story?.image}
                      />
                    </div>
                    <div className='right_sec'>
                      <div onClick={() => handleSetStory(story)}>
                        {story?.is_recommended && <p className='card_tags light_green'>Recommended</p>}
                        {story?.is_latest && <p className='card_tags light_green'>Latest</p>}
                        <h4 className='card_name_text'>
                          {globalThis.characterPreference === characterConst.traditional
                            ? converterType.converterTraditional(story?.storyTitle)
                            : converterType.converterSimplified(story?.storyTitle)}
                        </h4>
                        <p className='card_para_text'>{story?.englishTitle}</p>
                      </div>
                      <div className='contains_footer_div'>
                        <div className='story_creator_tooltip'>
                          <img alt={'Image'} src={'/images/maayot-green-logo-small.svg'} />
                          <div className='tooltip_creator_contains'>
                            <p className='tooltip_text'>All maayot stories are crafted by a professional teacher.</p>
                          </div>
                        </div>
                        {story?.knownWords || story?.knownWords === 0 || story?.newWords || story?.newWords === 0 ? (
                          <div className='contains_divider' />
                        ) : null}
                        {(story?.knownWords || story?.knownWords === 0) && (
                          <div className=' words_known_tooltip'>
                            <div className='d-flex align-items-center'>
                              {towerIcon(story?.diff)}
                              <label>{story?.knownWords}% words known</label>
                              <div className='tooltip_words_known_contains'>
                                <p className='tooltip_text'>Estimate of the words you likely know in this story.</p>
                              </div>
                            </div>
                          </div>
                        )}
                        {(story?.newWords || story?.newWords === 0) && (
                          <div className='new_words_tooltip'>
                            <div className='d-flex align-items-center'>
                              <img alt={'Image'} src={'/images/sparkles-icon.svg'} />
                              <label>{story?.newWords} new words</label>
                              <div className='tooltip_new_words_contains'>
                                <p className='tooltip_text'>
                                  Estimate of the new words you will be learning in this story.
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {story?.is_read && (
                      <div className='check_icon_div'>
                        <img alt={'Image'} src={'/images/check-icon.svg'} />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <label className='title_text_small_box'>
                      Want to read more?{' '}
                      <span onClick={() => handleSetStory(story)} className='link_text'>
                        Read our featured story
                      </span>{' '}
                    </label>
                    {story?.is_read && (
                      <div className='check_icon_div'>
                        <img alt={'Image'} src={'/images/check-icon.svg'} />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        ))
      )}
    </div>
  )
}

export default CommonCard
