import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import CountUp from 'react-countup'
import { useDispatch, useSelector } from 'react-redux'
import { getRecommended, getScore, notLearnedLanguage } from '../../redux/actions/onboardingAction'
import { RESET_ONBOARDING } from '../../redux/types'
import Marquee from 'react-fast-marquee'
import { STORIES } from 'routes/routeConstant'
import { checkAuthen } from '../../redux/actions/userActions'
import { Spinner } from 'react-bootstrap'
import { ResultIcon } from 'components/icons/icons'
import { useNavigate } from 'react-router-dom'
import { RootReducer } from '../../redux/stores'

const WordResult = ({ nextStep }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    languageInfo,
    wordResult,
    tempUser,
    is_result,
    learnAboutType,
    learnAboutTitle,
    learnAboutAnswer,
    loaderRecommended,
  } = useSelector(({ onboarding }: RootReducer) => onboarding)
  const user = JSON.parse(localStorage.getItem('user') || '')

  useEffect(() => {
    if (is_result && tempUser) {
      handle.getWordResult()
    }
    if (is_result === false && tempUser) {
      handle.notLearnedLanguage()
    }
  }, [tempUser, is_result])

  const handle = {
    getWordResult: async () => {
      await getScore({
        temp_user: tempUser,
        how_learn_about_maayot: learnAboutType,
        learn_about_question: learnAboutTitle?.question,
        learn_about_answer: learnAboutAnswer,
      })
    },
    notLearnedLanguage: async () => {
      await notLearnedLanguage({
        temp_user: tempUser,
        how_learn_about_maayot: learnAboutType,
        learn_about_question: learnAboutTitle?.question,
        learn_about_answer: learnAboutAnswer,
      })
    },
    navigate: (flag: boolean) => {
      if (flag) {
        getRecommended({ memberId: user?.memberId, languageId: languageInfo?._id }).then((res) => {
          checkAuthen().then(() => {
            navigate(STORIES)
            localStorage.removeItem('persist:onboardingmaayot')
            dispatch({ type: RESET_ONBOARDING })
            nextStep(1)
          })
        })
      } else {
        checkAuthen().then(() => {
          navigate(STORIES)
          localStorage.removeItem('persist:onboardingmaayot')
          dispatch({ type: RESET_ONBOARDING })
          nextStep(1)
        })
      }
    },
  }

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <>
      {loaderRecommended ? (
        <div className='loader_with_text_div'>
          <h4 className='loader_text'>Hang tight while we're finding the ideal story for your level</h4>
          <p className='loader_text_2'>This may take up to a minute</p>
          <div className='loader_main_div'>
            <Spinner animation='border' />
          </div>
        </div>
      ) : is_result ? (
        <div>
          <div className='w-container'>
            <div className='success-screen-div mob-lr-padding'>
              <div className='choose-lunguage-main-div'>
                <div className='result-img-div'>
                  <div
                    className='result-ray-img-div'
                    data-aos='zoom-in'
                    data-aos-duration='1000'
                    data-aos-delay='1800'
                    data-aos-once='true'
                  >
                    <img className='result-ray-img' alt={'icon'} src={'/images/Result-Rays-img.png'} />
                  </div>
                  <img
                    className='result-avtar-img'
                    alt={'icon'}
                    src={'/images/Result-Character-img.png'}
                    data-aos='fade-In'
                    data-aos-duration='1000'
                    data-aos-easing='linear'
                    data-aos-delay='1500'
                    data-aos-once='true'
                  />
                </div>
                <div className='result-bottom-contains-div'>
                  <div className='common-title-sec mb-15'>
                    <h2 className='screen-small-title'>You approximately know</h2>
                    <h2 className='screen-sub-title result-title counter-flex-div font-weight-700 DM-Sans-font'>
                      <CountUp duration={5} end={wordResult?.score} /> {''}
                      <div
                        data-aos='fade-left'
                        data-aos-duration='1000'
                        data-aos-easing='linear'
                        data-aos-delay='1100'
                        data-aos-once='true'
                      >
                        words in {wordResult?.knownWords?.language}
                      </div>
                    </h2>
                  </div>
                  <div
                    className='points-div'
                    data-aos='fade-down'
                    data-aos-duration='1000'
                    data-aos-delay='3000'
                    data-aos-once='true'
                  >
                    <p className='list-title'>Your knowledge is equivalent to:</p>
                    <ul className='common-points-list'>
                      {wordResult?.test_level?.map((level) => {
                        if (!level) return null
                        return (
                          <li>
                            <ResultIcon />
                            {level}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                  <div
                    className='text-center'
                    data-aos='zoom-in'
                    data-aos-duration='1000'
                    data-aos-delay='2500'
                    data-aos-once='true'
                  >
                    <a onClick={() => handle.navigate(true)}>
                      <button className='common-btn-cls green-btn-class'>Start learning with these words</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Marquee autoFill>{wordResult?.knownWords?.words?.map((word) => <p className='text'>{word}</p>)}</Marquee>
          <Marquee direction='right' autoFill>
            {wordResult?.knownWords?.words?.map((word) => <p className='text'>{word}</p>)}
          </Marquee>
        </div>
      ) : (
        <div className='w-container'>
          <div className='we-estimate-div mob-lr-padding'>
            <div className='choose-lunguage-main-div'>
              <div className='result-img-div'>
                <div className='No-worries-img-div'>
                  <div data-aos='fade-In' data-aos-duration='1000' data-aos-delay='1300' data-aos-once='true'>
                    <img className='No-worries-img1' alt={'icon'} src={'/images/No-worries-img1.png'} />
                  </div>
                  <img
                    className='No-worries-img2'
                    alt={'icon'}
                    src={'/images/No-worries-img2.png'}
                    data-aos='fade-right'
                    data-aos-duration='1000'
                    data-aos-delay='1500'
                    data-aos-once='true'
                  />
                </div>
              </div>
              <div className=''>
                <h2 className='screen-small-title Zilla-Slab-font'>We estimate you will learn</h2>
                <h2 className='screen-sub-title counter-flex-div font-weight-600 Zilla-Slab-font'>
                  <CountUp duration={1} end={127} />
                  <div
                    data-aos='fade-left'
                    data-aos-duration='1000'
                    data-aos-easing='linear'
                    data-aos-delay='1100'
                    data-aos-once='true'
                  >
                    words in {languageInfo?.language}, per month,
                    <br /> with maayot
                  </div>
                </h2>
              </div>
              <div className='text-center'>
                <a onClick={() => handle.navigate(false)}>
                  <button
                    className='common-btn-cls green-btn-class'
                    // onClick={() => handle.navigate()}
                    data-aos='fade-down'
                    data-aos-duration='1000'
                    data-aos-delay='2000'
                    data-aos-once='true'
                  >
                    Start learning
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* No worries! design end */}
    </>
  )
}

export default WordResult
