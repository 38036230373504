import { LIVE_MEET, PRO_MEET, STORIES } from 'routes/routeConstant'
import React, { useEffect } from 'react'
import { createRoom } from 'services/meet'
import { membershipNameType } from 'shared/constant'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootReducer } from '../../redux/stores'

export const createMeeting = async () => {
  const meetingId = 'xxxxyxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
  // console.log("http://" + window.location.host + "?meetingId=" + meetingId)
  return meetingId
}

const Meet: React.FC = () => {
  const navigate = useNavigate()
  const {membership} = useSelector(({user}: RootReducer) => user)
  const isTeacher = membership?.name === membershipNameType.Teacher || false

  useEffect(() => {
    if (!isTeacher) {
      navigate(STORIES)
    }
  }, [])

  const onClickCreateMeet = async (type: string) => {
    const id = await createMeeting()
    const Meeting = await createRoom(id)
    if (Meeting?.status === 200) {
    navigate(`${type}/${Meeting?.['data']?.customRoomId}`)
    }
  }

  return (
    <div className='createMeetWrapper'>
      <div className='createMeet' onClick={() => onClickCreateMeet(LIVE_MEET)}>
        <button className='button-2 w-button'>Create General Meeting</button>
      </div>
      <div className='createMeet' onClick={() => onClickCreateMeet(PRO_MEET)}>
        <button className='button-2 w-button'>Create Pro Meeting</button>
      </div>
    </div>
  )
}
export default Meet
