import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import CommonCard from 'components/common-card/card'
import UserInfo from 'components/user-info/user-info'
import UpgradeLabel from 'components/upgrade-label'
import { RootReducer } from '../../redux/stores'

const Stories = () => {
  const { storiesListLoader, welcomeMessage, CTAMessage } = useSelector(({ story }: RootReducer) => story)

  useEffect(() => {
    if (CTAMessage?.button === 'Get started') {
      window.location.replace(CTAMessage?.link)
    }
  }, [CTAMessage])

  return (
    <Container className='custom_container'>
      <UserInfo title={welcomeMessage} />
      <CommonCard />
      {!storiesListLoader && CTAMessage?.message && <UpgradeLabel />}
    </Container>
  )
}

export default Stories
