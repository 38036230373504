import React, { useEffect } from 'react'
import { VideoSDKMeeting } from '@videosdk.live/rtc-js-prebuilt'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { validateRoom } from 'services/meet'
import { DASHBOARD, STORIES } from '../../routes/routeConstant'
import { membershipNameType } from 'shared/constant'
import { RootReducer } from '../../redux/stores'

type Params = { id: string }

const Meet: React.FC = (props: any) => {
  const param = useParams<Params>()
  const navigate = useNavigate()
  const { id } = param || ''
  const meetingId = id
  const { membership, credentials }= useSelector(({ user }: RootReducer) => user)
  const name = credentials?.customFields?.['first-name'] || credentials?.metaData?.firstName

  const apiKey = process.env.REACT_APP_VIDEO_SDK_API_KEY
  const isTeacher = membership?.name === membershipNameType.Teacher || false

  const AllowedUsers = [membershipNameType.Teacher, membershipNameType.Pro]

  useEffect(() => {
    const initializeSDk = () => {
      const config = {
        containerId: 'meeting-container-id', // div id load frame to that div
        name: name, // name of current user
        meetingId: meetingId,
        apiKey: apiKey,
        redirectOnLeave: 'http://' + window.location.host + (isTeacher ? DASHBOARD : STORIES),
        screenShareEnabled: true,
        whiteboardEnabled: true,
        chatEnabled: true,
        webcamEnabled: false,
        layout: {
          type: 'SPOTLIGHT', // "SPOTLIGHT" | "SIDEBAR" | "GRID"
          priority: 'PIN', // "SPEAKER" | "PIN",
          // gridSize: 3,
        },
        participantCanToggleSelfMic: true,
        raiseHandEnabled: true,
        micEnabled: true,
        permissions: {
          pin: true,
          askToJoin: false,
          toggleParticipantWebcam: false,
          toggleParticipantMic: true,

          // only teacher can
          removeParticipant: isTeacher,
          endMeeting: isTeacher,
          // changeLayout: true, //can change layout
        },
        branding: {
          enabled: false,
        },
        joinScreen: {
          visible: true, // Show the join screen ?
          // title: "Daily scrum", // Meeting title
          meetingUrl: window.location.href, // Meeting joining url
        },
        isRecorder: false,
        recordingEnabled: false,
        // participantCanLeave: false, // if false, leave button won't be visible
        notificationSoundEnabled: true,
        debug: false,
      }
      const meeting: any = new VideoSDKMeeting()
      meeting.init(config)
    }
    async function validateMeetingID() {
      try {
        const res = await validateRoom(id || '')
        if (res?.status === 200) {
          initializeSDk()
        } else {
          // endSession(id)
          isTeacher ? navigate(DASHBOARD) : navigate(STORIES)
        }
      } catch (e) {
        console.error(e)
      }
    }

    if (props?.location?.pathname.slice(0, 4) === '/pro') {
      if (AllowedUsers.indexOf(membership?.name as membershipNameType) === -1) {
        isTeacher ? navigate(DASHBOARD) : navigate(STORIES)
      }
    }
    if (name) {
      validateMeetingID()
    }
  }, [name])

  return <div style={{ height: '100vh' }} id='meeting-container-id' />
}
export default Meet
