export const HOME = '/'
export const USER = '/user'
export const INTRODUCTION = '/introduction'
export const STORY_FINISHED = '/story-finished'
export const ACCOUNT = '/account'
export const MEMBERS_ACCOUNT = '/members/account'
export const LOGIN = '/login'
export const CHOOSE_LANGUAGE = '/choose-language'
export const LEAD_USER_PATH = '/:id/lead'
export const OLD_USER_PATH = '/:id/existing'
export const NEW_USER_PATH = '/:id/new'
export const WORD_RESULT = '/word-result'
export const NOT_FOUND = '/404'
export const STORY = '/story'
export const QUIZ = '/quiz'
export const QUESTION = '/question'
export const SUCCESS_PAID = '/success-paid'
export const SUCCESS = '/success'
export const DASHBOARD = '/dashboard'
export const CREATE_MEET = '/dashboard/meeting'
export const LIVE_MEET = '/live'
export const PRO_MEET = '/pro'
export const CORRECTIONS = '/correction'
export const PASTSTORIES = '/past-stories'
export const STORY_STREAKS = '/integrations/beeminder'
export const STORIES = '/stories'

// temp route
export const LEAD_USER2_PATH = '/:id/lead2'
export const NEW_USER2_PATH = '/:id/new2'
