import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import { membershipNameType, membershipType } from '../shared/constant'
import WithNav from '../containers/WithNav'
import { getProfile } from '../redux/actions/myAccountAction'
import { NOT_FOUND, STORY_FINISHED } from './routeConstant'
import HeaderNew from 'components/header-new/header'
import { isSunday } from '../utils/utils'
import { RootReducer } from '../redux/stores'

interface IMyRouteProps {
  component: React.ComponentType<any>
  authenticated: boolean
  status: membershipType
  membershipPlan: membershipNameType
  currentStep: number
  rest?: any
  showNav?: boolean
  showDashboard?: boolean
  showCorrections?: boolean
  path?: string
  memberId?: string
  isMeeting?: boolean
}

const PrivateRoute: React.FC<IMyRouteProps> = ({
  component: Component,
  authenticated,
  membershipPlan,
  showNav,
  showDashboard,
  isMeeting,
  memberId,
  path,
  ...rest
}) => {
  const isTeacher = membershipPlan === membershipNameType.Teacher
  const isPro = membershipPlan === membershipNameType.Pro
  const isDashboard = showDashboard && isTeacher
  const isFree = membershipPlan === membershipNameType.Free
  const { isMobNav } = useSelector(({ dashboard }: RootReducer) => dashboard)

  useEffect(() => {
    if (authenticated && memberId) {
      getProfile(memberId)
    }
  }, [authenticated, memberId, getProfile])

  const renderComponent = () => {
    if (showNav) {
      return (
        <div className={!isMobNav ? 'z_index_div common_page' : 'common_page'}>
          <div
            className={
              isFree && !isSunday() && path === STORY_FINISHED
                ? 'section_div scroll_contain_div_is_free'
                : 'section_div scroll_contain_div'
            }
          >
            <WithNav step={rest.currentStep}>
              <Component />
            </WithNav>
          </div>
        </div>
      )
    }

    if (!showNav && !isTeacher && !isDashboard && path !== '/correction') {
      return (
        <div className='common_page'>
          <div className='section_div'>
            <Component />
          </div>
        </div>
      )
    }

    if (!showNav && isTeacher && path !== '/correction') {
      if (isMeeting) {
        return (
          <div className='common_page'>
            <div className='section_div'>
              <Component />
            </div>
          </div>
        )
      }
      return <Component />
    }

    if (!showNav && isPro) {
      return (
        <div className='common_page'>
          <div className='section_div'>
            <Component />
          </div>
        </div>
      )
    }

    return <Navigate to={NOT_FOUND} />
  }

  return (
    <>
      {authenticated ? (
        <>
          {!isDashboard && <HeaderNew />}
          {renderComponent()}
        </>
      ) : (
        <Navigate to='/login' />
      )}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  authenticated: state.user.authenticated,
  currentStep: state.story.currentStep,
  status: state.user.membership.status,
  membershipPlan: state.user?.membership?.name,
  memberId: state.user.credentials?.memberId || '',
})

export default connect(mapStateToProps)(PrivateRoute)
