import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// import LearnWith from './LearnWith'
import WritingMethod from './WritingMethod'
import VocabularyTest from './VocabularyTest'
import WordResult from './WordResult'
import { useDispatch, useSelector } from 'react-redux'
import {
  SET_ALL_LANGUAGE,
  SET_ALL_WORDS,
  // SET_ISFREETRIAL,
  SET_ISLEADUSER,
  SET_LANGUAGE_INFO,
  SET_LEARN_ABOUT_ANSWER,
  SET_LEARN_ABOUT_TITLE,
  SET_LEARN_ABOUT_TYPE,
  SET_STEPPER_STEP,
  SET_USERTYPE,
} from '../../redux/types'
import SignUp from './SignUp'
import { getAllLanguage, getWordsList } from '../../redux/actions/onboardingAction'
import Tooltip from 'containers/tooltip/Tooltip'
import HowDidYouHear from 'components/onboarding/how-did-you-hear'
import SurveyForm from 'components/onboarding/survey-form'
import HaveLearnedLanguage from './have-learned-language'
import FreeTrialSignUp from './FreeTrialSignUp'
import { RootReducer } from '../../redux/stores'

export const renderStepIcon = (step, stepNumber, onClickStep, title) => {
  return (
    <Tooltip text={title} disabled={stepNumber >= step}>
      <div
        className={`step-indicator-item ${step === stepNumber ? 'active' : ''}`}
        onClick={() => onClickStep(stepNumber)}
      ></div>
    </Tooltip>
  )
}

const OnBoarding = () => {
  const { pathname } = useLocation()
  const { id }: any = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userType = pathname.split('/')[2]
  const isFreeTrial = userType === 'new' || userType === 'lead'
  const userState = useSelector(({ user }: RootReducer) => user)
  const { languageInfo, stepper_step, languages, isLeadUser, is_result, learnAboutType } = useSelector(
    ({ onboarding }: RootReducer) => onboarding,
  )

  useEffect(() => {
    if ((isLeadUser && stepper_step === 7) || stepper_step === 8) {
      nextStep(isLeadUser ? 7 : 8)
      return
    }
    if (
      pathname.includes('/existing') &&
      !userState?.authenticated
      // ||
      // ([2, 3, 4, 5, 6, 7, 8].includes(stepper_step) && !userState?.authenticated)
    ) {
      navigate('/login')
      return
    } else if ((pathname.includes('/new') || pathname.includes('/new2')) && userState?.authenticated) {
      nextStep(2)
      return
    } else if ((pathname.includes('/lead') || pathname.includes('/lead2')) && userState?.authenticated) {
      nextStep(5)
      return
    } else if (pathname.includes('/existing') && userState?.authenticated) {
      nextStep(4)
      return
    } else if (isLeadUser) {
      return
    } else {
      nextStep(1)
      return
    }
  }, [pathname, userState?.authenticated])

  const nextStep = (val) => {
    dispatch({ type: SET_STEPPER_STEP, payload: val })
    if (val === 1) {
      dispatch({ type: SET_LEARN_ABOUT_TYPE, payload: '' })
      dispatch({ type: SET_LEARN_ABOUT_TITLE, payload: '' })
      dispatch({ type: SET_LEARN_ABOUT_ANSWER, payload: '' })
    }
  }

  useEffect(() => {
    handle.pageLoad()
  }, [pathname])

  const handle = {
    pageLoad: async () => {
      dispatch({ type: SET_USERTYPE, payload: userType })
      if (pathname.includes('/lead') || pathname.includes('/lead2')) {
        dispatch({ type: SET_ISLEADUSER, payload: true })
      } else {
        dispatch({ type: SET_ISLEADUSER, payload: false })
      }
      if (stepper_step !== 8 && languages?.length <= 0) {
        const newLan = id.split('-')[1]
        const newLanguage = newLan.charAt(0).toUpperCase() + newLan.slice(1)
        const response = await getAllLanguage()

        dispatch({ type: SET_ALL_LANGUAGE, payload: response })

        if (newLanguage) {
          const newLanguageInfo = response?.find((languageInfo) => languageInfo?.language === newLanguage)

          const newPreferences = newLanguageInfo?.preferences?.map((preference, i) => ({
            ...preference,
            is_active: i === 0 ? true : false,
          }))

          dispatch({ type: SET_LANGUAGE_INFO, payload: { ...newLanguageInfo, preferences: newPreferences } })

          if (newLanguageInfo && !newLanguageInfo?.preferences?.length) {
            const response = await getWordsList({ language_id: newLanguageInfo?._id })
            dispatch({ type: SET_ALL_WORDS, payload: { wordList: response?.data } })
          }
        }
      }
    },
    onClickStep: (stepNum) => {
      if (stepNum > stepper_step) {
      } else {
        if ((isLeadUser && stepNum === 5) || stepNum === 2) {
          dispatch({ type: SET_LEARN_ABOUT_TYPE, payload: '' })
          dispatch({ type: SET_LEARN_ABOUT_TITLE, payload: '' })
          dispatch({ type: SET_LEARN_ABOUT_ANSWER, payload: '' })
        }
        nextStep(stepNum)
      }
    },
  }

  return (
    <>
      {isLeadUser ? (
        <div className={[4, 7].includes(stepper_step) ? '' : 'mob-lr-padding'}>
          <div className={[4, 7].includes(stepper_step) ? '' : 'common-stepper-padding'}>
            {[4, 7].includes(stepper_step) ? null : (
              <div className='stapper-main-div'>
                <div className='stapper-logo-main-div'>
                  <img className='stapper-logo' src={'/images/maayot-green-logo-small.svg'} alt='Image' />
                </div>
                <div className='stapper-circle-div'>
                  {renderStepIcon(stepper_step, 1, handle.onClickStep, 'Experience')}
                  {is_result ? (
                    <>
                      {!languageInfo?.preferences?.length
                        ? null
                        : renderStepIcon(stepper_step, 2, handle.onClickStep, 'Writing Method')}
                      {renderStepIcon(stepper_step, 3, handle.onClickStep, 'Words Test')}
                    </>
                  ) : null}
                  {renderStepIcon(stepper_step, 5, handle.onClickStep, 'Survey')}
                  {learnAboutType && renderStepIcon(stepper_step, 6, handle.onClickStep, 'Survey')}
                </div>
              </div>
            )}
            {stepper_step === 1 && <HaveLearnedLanguage nextStep={nextStep} />}
            {stepper_step === 2 && <WritingMethod nextStep={nextStep} />}
            {stepper_step === 3 && <VocabularyTest nextStep={nextStep} />}
            {stepper_step === 4 ? isFreeTrial ? <FreeTrialSignUp /> : <SignUp /> : null}
            {stepper_step === 5 && <HowDidYouHear nextStep={nextStep} />}
            {stepper_step === 6 && <SurveyForm nextStep={nextStep} />}
            {stepper_step === 7 && <WordResult nextStep={nextStep} />}
          </div>
        </div>
      ) : (
        <div className={[1, 7].includes(stepper_step) ? '' : 'mob-lr-padding'}>
          <div className={[1, 7].includes(stepper_step) ? '' : 'common-stepper-padding'}>
            {[1, 7].includes(stepper_step) ? null : (
              <div className='stapper-main-div'>
                {/* {stepper_step === 1 && (
              <img
                className='back-arrow-img'
                alt={'icon'}
                src={'/images/arrow-back.png'}
                onClick={() => navigate('/')}
              />
            )} */}
                <div className='stapper-logo-main-div'>
                  <img className='stapper-logo' src={'/images/maayot-green-logo-small.svg'} alt='Image' />
                </div>
                <div className='stapper-circle-div'>
                  {/* {pathname.includes('/existing')
                  ? null
                  : renderStepIcon(stepper_step, 2, handle.onClickStep, 'Methodology')} */}
                  {pathname.includes('/existing') ? null : (
                    <>
                      {renderStepIcon(stepper_step, 2, handle.onClickStep, 'Survey')}
                      {learnAboutType && renderStepIcon(stepper_step, 3, handle.onClickStep, 'Survey')}
                    </>
                  )}
                  {renderStepIcon(stepper_step, 4, handle.onClickStep, 'Experience')}
                  {is_result ? (
                    <>
                      {!languageInfo?.preferences?.length
                        ? null
                        : renderStepIcon(stepper_step, 5, handle.onClickStep, 'Writing Method')}
                      {renderStepIcon(stepper_step, 6, handle.onClickStep, 'Words Test')}
                    </>
                  ) : null}
                </div>
              </div>
            )}
            {stepper_step === 1 ? isFreeTrial ? <FreeTrialSignUp /> : <SignUp /> : null}
            {/* {stepper_step === 2 && <LearnWith nextStep={nextStep} />} */}
            {pathname.includes('/existing') ? null : (
              <>
                {stepper_step === 2 && <HowDidYouHear nextStep={nextStep} />}
                {stepper_step === 3 && <SurveyForm nextStep={nextStep} />}
              </>
            )}
            {stepper_step === 4 && <HaveLearnedLanguage nextStep={nextStep} />}
            {stepper_step === 5 && <WritingMethod nextStep={nextStep} />}
            {stepper_step === 6 && <VocabularyTest nextStep={nextStep} />}
            {stepper_step === 7 && <WordResult nextStep={nextStep} />}
          </div>
        </div>
      )}
    </>
  )
}

export default OnBoarding
