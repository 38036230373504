import Translate from 'containers/popup/Translate'
import StoryIntroCard from 'containers/storiesIntroCard'
import StoryIntro from 'containers/storyIntro'
import React, { useEffect, useRef, useState } from 'react'
import { translate } from '../../redux/actions/storyAction'
import { AddWordToHackChinese, handleAddWordToSkritter } from '../../redux/actions/userActions'
import { characterConst, converterType, wordType } from 'shared/constant'
import StoryTitle from './StoryTitle'
interface IStoriesCardProps {
  firstLineStory?: any
  user_auth_token: any
  skritter_token: any
  chineseWordFontSize: any
}

const StoriesCard: React.FC<IStoriesCardProps> = (props: any) => {
  const { firstLineStory, chineseWordFontSize } = props

  const [showPinYin, setShowPinYin] = useState(false)

  const [activeIndex, setActiveIndex] = useState('-1')
  const contentStoryRef = useRef(document.createElement('div'))
  const [transVisible, setTransVisible] = useState(false)
  const [transData, setTransData] = useState({})
  const popupRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    document.addEventListener('scroll', onTranslateClose)
    document.addEventListener('click', onDocumentClick)
    return () => {
      document.removeEventListener('scroll', onTranslateClose)
      document.removeEventListener('click', onDocumentClick)
    }
  }, [contentStoryRef, props.firstLineStory])

  const newWord1 = !firstLineStory?.newWord1
    ? ''
    : globalThis.characterPreference === characterConst.traditional
      ? converterType.converterTraditional(firstLineStory?.newWord1)
      : converterType.converterSimplified(firstLineStory?.newWord1)
  const newWord2 = !firstLineStory?.newWord2
    ? ''
    : globalThis.characterPreference === characterConst.traditional
      ? converterType.converterTraditional(firstLineStory?.newWord2)
      : converterType.converterSimplified(firstLineStory?.newWord2)

  const handleTranslate = (event) => {
    if (
      (event.type === 'mouseover' || event.type === 'click') &&
      event.target.innerText &&
      event.target.innerText?.length > 0
    ) {
      trans(event.target.innerText, event.clientY, event.clientX)
    }
  }

  const handleClickWord = (event, key) => {
    handleTranslate(event)
    setActiveIndex(activeIndex === key ? `-1` : key)
    event.preventDefault()
    event.stopPropagation()
  }
  const setTranslate = (word: string, posTop: number, posLeft: number, data: any) => {
    setTransVisible(true)

    const isOldWord = firstLineStory?.oldWords?.includes(word)

    const type =
      newWord1 === word || newWord2 === word ? wordType.NewWord : isOldWord ? wordType.OldWord : wordType.Word

    setTransData({
      type,
      top: posTop,
      left: posLeft,
      word,
      definition: data.pinyin,
      example1: data.english,
      example2: '',
      level: 'HSK 5',
    })
  }

  const trans = (word: string, posTop: number, posLeft: number) => {
    const allData = [...firstLineStory.arContent, ...firstLineStory.storyTitleContent]

    if (/^[&.-”“，、；？@!#$%^&*()]+$/.test(word) || word.trim() === '。') {
      setTransVisible(false)
      return
    }
    const wordTranslate = allData.filter(
      (iword) =>
        (globalThis.characterPreference === characterConst.traditional
          ? converterType.converterTraditional(iword.word)
          : converterType.converterSimplified(iword.word)) === word,
    )
    if (wordTranslate && wordTranslate.length > 0) {
      setTranslate(word, posTop, posLeft, wordTranslate[0])
      if (props?.user_auth_token) {
        AddWordToHackChinese(wordTranslate[0], props?.user_auth_token)
      }
      if (props?.skritter_token) {
        handleAddWordToSkritter({
          word,
          translate: wordTranslate[0],
          title: props?.story?.storyTitleContent,
          skritter_token: props?.skritter_token,
        })
      }
    } else {
      translate(word)
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          if (data && data.length > 0) {
            setTranslate(word, posTop, posLeft, data[0])
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const onTranslateClose = () => {
    setTransVisible(false)
  }
  const onDocumentClick = (e) => {
    if (!popupRef?.current?.contains(e.target)) {
      setTransVisible(false)
    }
  }
  return (
    <>
      <>
        <div className='story-content correction-common-padding border-top section-story'>
          <h4>
            {firstLineStory?.storyTitleContent &&
              (globalThis.characterPreference === characterConst.traditional ? (
                <StoryTitle
                  storyTitleContent={firstLineStory?.storyTitleContent}
                  showPinYin={showPinYin}
                  story={firstLineStory}
                  activeIndex={activeIndex}
                  handleClickWord={handleClickWord}
                  chineseWordFontSize={chineseWordFontSize}
                />
              ) : (
                <StoryTitle
                  storyTitleContent={firstLineStory?.storyTitleContent}
                  showPinYin={showPinYin}
                  story={firstLineStory}
                  activeIndex={activeIndex}
                  handleClickWord={handleClickWord}
                  chineseWordFontSize={chineseWordFontSize}
                />
              ))}
          </h4>
          <div className='w-row section-story-content'>
            {firstLineStory && firstLineStory?.arContent.length ? (
              <StoryIntroCard
                handleClickWord={handleClickWord}
                activeIndex={activeIndex}
                showPinYin={showPinYin}
                story={firstLineStory}
                chineseWordFontSize={chineseWordFontSize}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </>
      <Translate ref={popupRef} visible={transVisible} data={transData} onClose={onTranslateClose} />
    </>
  )
}
export default React.memo(StoriesCard)
