import React, { useState } from 'react'
import './header.css'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { ACCOUNT, CORRECTIONS, DASHBOARD, PASTSTORIES, STORIES } from 'routes/routeConstant'
import { FONT_SIZE_LIST } from 'components/account/const'
import { useDispatch, useSelector } from 'react-redux'
import { SET_CHINESE_WORD_FONT_SIZE, SET_IS_MOB_NAV } from '../../redux/types'
import { logoutUser } from '../../redux/actions/userActions'
import { getPlanInfo, membershipNameType, PlanChangeLink } from 'shared/constant'

import { CorrectionStoryIcon, CurrentStoryIcon, DashboardIcon, SettingIcon, TextSizeIcon } from 'components/icons/icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { RootReducer } from '../../redux/stores'

const HeaderNew = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {pathname:pathName} = useLocation()
  const [isOpen, setOpen] = useState(false)
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const { chineseWordFontSize, isMobNav } = useSelector(({ dashboard }: RootReducer) => dashboard)
  const { membership, authenticated } = useSelector(({ user }: RootReducer) => user)

  const planName = membership?.name

  const isFree = membership?.name == membershipNameType.Free
  // const isPremium = membership?.name == membershipNameType.Premium
  const isStandard = membership?.name == membershipNameType.Standard
  // const isSchool = membership?.name == membershipNameType.School
  const isPro = membership?.name == membershipNameType.Pro
  const isTeacher = membership?.name == membershipNameType.Teacher

  const onChangeFontSize = (elm) => {
    setOpen(false)
    setDropdownOpen(false)
    dispatch({ type: SET_CHINESE_WORD_FONT_SIZE, payload: elm })
  }

  const onLogout = () => {
    logoutUser()
  }

  const redirectToAccount = () => {
    window.open(PlanChangeLink)
  }

  return (
    <div className='header_main_div'>
      <Navbar collapseOnSelect expand='lg'>
        <Container className='mb-0'>
          <Navbar.Brand href={STORIES}>
            <label className='header_plan_text'>
              <img alt={'icon'} src={'/images/maayot-text-svg.svg'} />
              <span>{getPlanInfo(planName)?.title}</span>
            </label>
          </Navbar.Brand>
          {authenticated && (
            <div>
              <div className='nav_toggle_div'>
                {isMobNav ? (
                  <Navbar.Toggle
                    onClick={() => dispatch({ type: SET_IS_MOB_NAV, payload: false })}
                    aria-controls='responsive-navbar-nav'
                  />
                ) : (
                  <Nav.Link className='main_link' onClick={() => dispatch({ type: SET_IS_MOB_NAV, payload: true })}>
                    <img alt={'icon'} src={'/images/close-icon.svg'} className='close_icon' />
                  </Nav.Link>
                )}

                {/* <img alt={'icon'} src={'/images/aa-icon.svg'} /> */}
                {/* {!pathName?.includes('/stories') && ( */}
                <div className='custom_dropdown_main_div'>
                  <Nav.Link className='main_link d-flex' onClick={() => setOpen(!isOpen)}>
                    <img alt={'icon'} src={'/images/aa-icon.svg'} />
                  </Nav.Link>
                  {isOpen && (
                    <div className='custom_dropdown_div'>
                      <div className='up_arrow_div'></div>
                      <ul>
                        {FONT_SIZE_LIST.map((elm, index) => (
                          <li
                            key={index}
                            className={`${
                              chineseWordFontSize?.storyContent === elm?.storyContent && 'active'
                            } dropdown_link`}
                            onClick={() => onChangeFontSize(elm)}
                          >
                            {elm.label}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                {/* )} */}
              </div>
              {isMobNav ? (
                <Navbar.Collapse id='responsive-navbar-nav' style={{ display: isMobNav ? 'none' : 'block' }}>
                  <div className='mob_header_div'>
                    <div>
                      {/* <img alt={'icon'} src={'/images/logo-white.png'} /> */}
                      <label className='header_plan_text'>{planName}</label>
                    </div>
                    <img
                      alt={'icon'}
                      src={'/images/close-icon.svg'}
                      className='close_icon'
                      onClick={() => dispatch({ type: SET_IS_MOB_NAV, payload: !isMobNav })}
                    />
                  </div>
                  <Nav className='ms-auto header_menu_div'>
                    <div className='disable-correction-container new-tooltip' tabIndex={1}>
                      {/* <Nav.Link href={pathName?.includes('/past-stories') ? STORIES : PASTSTORIES}> */}
                      <span
                        className='nav-link'
                        onClick={() => {
                          if (isStandard || isPro) {
                            navigate(pathName?.includes(PASTSTORIES) ? STORIES : PASTSTORIES)
                            dispatch({ type: SET_IS_MOB_NAV, payload: true })
                          }
                        }}
                      >
                        <CurrentStoryIcon />
                        {pathName?.includes(PASTSTORIES) ? 'Current stories' : 'Past stories'}
                      </span>
                      {/* <label className='header_count_div'>2</label> */}
                      {/* </Nav.Link> */}

                      {(isFree || isTeacher) && (
                        <div className='disable-correction-tooltip Corrections-tooltip new-tooltip-conation'>
                          <div className='tooltiptextWriter' id='tooltiptextWriter'>
                            <button className='tooltiptext-close'>
                              <i className='fa fa-times' aria-hidden='true'></i>
                            </button>
                            {isTeacher
                              ? 'Get your speaking and writing corrected daily by a Teacher. Only for Pro.'
                              : 'Review the past stories along with their audio and new words. Only for Standard and Pro.'}
                            &nbsp;
                            <a onClick={redirectToAccount} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                              Upgrade now
                            </a>
                          </div>
                        </div>
                      )}
                    </div>

                    {(isFree || isPro || isStandard) && (
                      <div className='disable-correction-container new-tooltip' tabIndex={1}>
                        <span
                          className='nav-link'
                          onClick={() => {
                            if (isPro) {
                              navigate(pathName?.includes(CORRECTIONS) ? STORIES : CORRECTIONS)
                              dispatch({ type: SET_IS_MOB_NAV, payload: true })
                            }
                          }}
                        >
                          {pathName?.includes(CORRECTIONS) ? (
                            <CorrectionStoryIcon />
                          ) : (
                            <i className='fa fa-pencil' style={{ fontSize: '14px', textDecoration: 'underline' }}></i>
                          )}
                          {pathName?.includes(CORRECTIONS) ? 'Current stories' : 'Corrections'}
                        </span>

                        {(isFree || isStandard) && (
                          <div className='disable-correction-tooltip Corrections-tooltip new-tooltip-conation'>
                            <div className='tooltiptextWriter' id='tooltiptextWriter'>
                              <button className='tooltiptext-close'>
                                <i className='fa fa-times' aria-hidden='true'></i>
                              </button>
                              Get your speaking and writing corrected daily by a Teacher. Only for Pro. &nbsp;
                              <a onClick={redirectToAccount} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                Upgrade now
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {/* {!pathName?.includes('/stories') && ( */}
                    {/* <NavDropdown title='Aa Text size' id='collapsible-nav-dropdown' > */}
                    <span className='mob_dropdown_link'>
                      <Nav.Link className='main_link' onClick={() => setDropdownOpen(!isDropdownOpen)}>
                        <TextSizeIcon />
                        Text size
                      </Nav.Link>
                      {isDropdownOpen && (
                        <div className='custom_dropdown_div_one'>
                          <div className='up_arrow_div'></div>
                          <ul>
                            {FONT_SIZE_LIST.map((elm, index) => (
                              <li>
                                <NavDropdown.Item
                                  key={index}
                                  onClick={() => onChangeFontSize(elm)}
                                  className={`${
                                    chineseWordFontSize?.storyContent === elm?.storyContent && 'active'
                                  } dropdown_link`}
                                >
                                  {elm.label}
                                </NavDropdown.Item>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </span>
                    {/* </NavDropdown> */}

                    {/* )} */}
                    {isTeacher && (
                      <Nav.Link href={DASHBOARD}>
                        <DashboardIcon />
                        Dashboard
                      </Nav.Link>
                    )}
                    {!isTeacher && (
                      <Nav.Link href={ACCOUNT}>
                        <SettingIcon color={'white'} />
                        Settings
                      </Nav.Link>
                    )}
                    <Nav.Link onClick={() => onLogout()} className='logout_link divider_cls'>
                      Logout
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              ) : null}
            </div>
          )}
        </Container>
      </Navbar>
      {authenticated && (
        <div className={!isMobNav ? 'show-menu' : 'show-menu hide'}>
          {!isMobNav ? (
            <div className='navbar-collapse1'>
              <div className='ms-auto header_menu_div' id='responsive-navbar-nav'>
                <div className='disable-correction-container new-tooltip' tabIndex={1}>
                  {/* <Nav.Link href={pathName?.includes('/past-stories') ? STORIES : PASTSTORIES}> */}
                  <span
                    className='nav-link'
                    onClick={() => {
                      if (isStandard || isPro) {
                        navigate(pathName?.includes(PASTSTORIES) ? STORIES : PASTSTORIES)
                        dispatch({ type: SET_IS_MOB_NAV, payload: true })
                      }
                    }}
                  >
                    <CurrentStoryIcon />
                    {pathName?.includes(PASTSTORIES) ? 'Current stories' : 'Past stories'}
                  </span>
                  {/* <label className='header_count_div'>2</label> */}
                  {/* </Nav.Link> */}

                  {(isFree || isTeacher) && (
                    <div className='disable-correction-tooltip Corrections-tooltip new-tooltip-conation'>
                      <div className='tooltiptextWriter' id='tooltiptextWriter'>
                        <button className='tooltiptext-close'>
                          <i className='fa fa-times' aria-hidden='true'></i>
                        </button>
                        {isTeacher
                          ? 'Get your speaking and writing corrected daily by a Teacher. Only for Pro.'
                          : 'Review the past stories along with their audio and new words. Only for Standard and Pro.'}{' '}
                        &nbsp;
                        <a onClick={redirectToAccount} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                          Upgrade now
                        </a>
                      </div>
                    </div>
                  )}
                </div>

                {(isFree || isPro || isStandard) && (
                  <div className='disable-correction-container new-tooltip' tabIndex={1}>
                    <span
                      className='nav-link'
                      onClick={() => {
                        if (isPro) {
                          navigate(pathName?.includes(CORRECTIONS) ? STORIES : CORRECTIONS)
                          dispatch({ type: SET_IS_MOB_NAV, payload: true })
                        }
                      }}
                    >
                      {pathName?.includes(CORRECTIONS) ? (
                        <CurrentStoryIcon />
                      ) : (
                        <i className='fa fa-pencil' style={{ fontSize: '14px', textDecoration: 'underline' }}></i>
                      )}
                      {pathName?.includes(CORRECTIONS) ? 'Current stories' : 'Corrections'}
                    </span>

                    {(isFree || isStandard) && (
                      <div className='disable-correction-tooltip Corrections-tooltip new-tooltip-conation'>
                        <div className='tooltiptextWriter' id='tooltiptextWriter'>
                          <button className='tooltiptext-close'>
                            <i className='fa fa-times' aria-hidden='true'></i>
                          </button>
                          Get your speaking and writing corrected daily by a Teacher. Only for Pro. &nbsp;
                          <a onClick={redirectToAccount} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                            Upgrade now
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {/* {!pathName?.includes('/stories') && ( */}
                {/* <NavDropdown title='Aa Text size' id='collapsible-nav-dropdown' > */}
                <span className='mob_dropdown_link'>
                  <Nav.Link className='main_link' onClick={() => setDropdownOpen(!isDropdownOpen)}>
                    <TextSizeIcon />
                    Text size
                  </Nav.Link>
                  {isDropdownOpen && (
                    <div className='custom_dropdown_div_one'>
                      <div className='up_arrow_div'></div>
                      <ul>
                        {FONT_SIZE_LIST.map((elm, index) => (
                          <li>
                            <NavDropdown.Item
                              key={index}
                              onClick={() => onChangeFontSize(elm)}
                              className={`${
                                chineseWordFontSize?.storyContent === elm?.storyContent && 'active'
                              } dropdown_link`}
                            >
                              {elm.label}
                            </NavDropdown.Item>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </span>
                {/* </NavDropdown> */}

                {/* )} */}
                {isTeacher && (
                  <Nav.Link href={DASHBOARD}>
                    <DashboardIcon />
                    Dashboard
                  </Nav.Link>
                )}
                {!isTeacher && (
                  <Nav.Link href={ACCOUNT}>
                    <SettingIcon color={'white'} />
                    Settings
                  </Nav.Link>
                )}
                <Nav.Link onClick={() => onLogout()} className='logout_link divider_cls'>
                  Logout
                </Nav.Link>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default HeaderNew
