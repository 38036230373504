import React, { useEffect, useState } from 'react'
import './user-info.css'
import { useSelector } from 'react-redux'
import { getCurrentStreak } from '../../redux/actions/streakActions'
import { StreakIcon } from 'components/icons/icons'
import { RootReducer } from '../../redux/stores'

const UserInfo = ({ title }) => {
  const { credentials } = useSelector(({ user }: RootReducer) => user)
  const { storiesListLoader } = useSelector(({ story }: RootReducer) => story)
  const { current } = useSelector(({ streak }: RootReducer) => streak)
  const { customFields, memberId } = credentials
  const [streakCount, setStreakCount] = useState<string[]>([])
  const username = customFields?.['first-name']

  useEffect(() => {
    if (memberId) {
      getCurrentStreak(memberId)
      // getLongestStreak(memberId)
    }
  }, [memberId])

  useEffect(() => {
    if (current) {
      setStreakCount(getStreak(current?.counter))
    }
  }, [current])

  const getStreak = (count: number) => {
    if (count > 3) {
      return ['#E47D5D', '#E47D5D', '#E47D5D']
    }
    switch (count) {
      case 0:
        return ['#B6B6B6', '#B6B6B6', '#B6B6B6']
      case 1:
        return ['#B6B6B6', '#B6B6B6', '#E47D5D']
      case 2:
        return ['#B6B6B6', '#E47D5D', '#E47D5D']
      case 3:
        return ['#E47D5D', '#E47D5D', '#E47D5D']
      default:
        return ['#B6B6B6', '#B6B6B6', '#B6B6B6']
    }
  }

  return (
    <div>
      {storiesListLoader ? (
        <div className='user_info_skeleton_loader'>
          <div className='user_info_skeleton_left'></div>
          <div className='user_info_skeleton_right'></div>
        </div>
      ) : (
        <div className='user_info_main_div'>
          {title && (
            <>
              <h6>
                <span className='fw-700'>Hi {username},</span> {title}
              </h6>

              <div className='d-flex align-items-center'>
                <div className='d-flex'>{streakCount?.map((streak, i) => <StreakIcon color={streak} key={i} />)}</div>
                <p className='days_text'>
                  {current?.counter} {current?.counter > 1 ? 'days' : 'day'} streak
                </p>
              </div>
            </>
          )}
          {/* )} */}
        </div>
      )}
    </div>
  )
}

export default UserInfo
