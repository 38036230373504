import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getCompletedStreak } from '../../redux/actions/streakActions'

const StoryCompletedStreak: React.FC = (props: any) => {
  const { pathname } = useLocation()
  const { getCompletedStreak } = props
  const pathArr = pathname.split('/')
  const memberId = pathArr?.slice?.(-2)?.[0]
  const [completedStreak, setCompletedStreak] = useState(false)
  useEffect(() => {
    if (memberId) {
      getCompletedStreak(memberId)
        .then((res) => setCompletedStreak(res))
        .catch((e) => {
          console.error(e)
        })
    }
  }, [memberId])

  return <>{completedStreak || 0}</>
}

const mapStateToProps = () => ({})
const mapActionsToProps = {
  getCompletedStreak,
}
export default connect(mapStateToProps, mapActionsToProps)(StoryCompletedStreak)
