import React, { useEffect, useMemo, useRef, useState } from 'react'
import { convertAudioTime, getBlobDuration } from '../../utils/utils'
import useRecorder from 'shared/useRecorder'
import { connect } from 'react-redux'
import { setAudioRecording } from '../../redux/actions/recorderAction'

let nIntervId: any = null
const Recorder: React.FC = (props: any) => {
  const { audioURL, isRecording, startRecording, stopRecording } = useRecorder()
  const [play, setPlay] = useState(false)
  const [record, setRecord] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [audio, setAudio] = useState('')
  const audioRef = useMemo(() => new Audio(audio), [audio])
  const onMove = useRef<boolean>(false)
  const barRef = useRef(document.createElement('div'))
  const barAnchor = useRef(document.createElement('div'))
  const barProgress = useRef(document.createElement('div'))
  const durationTimeText = useMemo(() => convertAudioTime(duration), [duration])
  const currentTimeText = useMemo(() => convertAudioTime(currentTime), [currentTime])

  const [recordingLength, setRDuration] = useState<number>(0)

  useEffect(() => {
    clearInterval(nIntervId)
    // release our intervalID from the variable
    nIntervId = null
    if (isRecording) {
      nIntervId = setInterval(() => {
        setRDuration(recordingLength + 1)
      }, 1000) // 1 second
    } else {
      setRDuration(0)
    }
  }, [isRecording, recordingLength])

  useEffect(() => {
    audioRef.addEventListener('loadedmetadata', async () => {
      if (audioRef.duration !== Infinity) {
        setDuration(audioRef.duration)
      } else if (!duration) {
        try {
          const totalDuration = await getBlobDuration(audio)
          setDuration(totalDuration)
        } catch (err) {
          setDuration(audioRef.duration)
        }
      }
    })
    audioRef.addEventListener('timeupdate', () => {
      if (!audioRef.paused) setCurrentTime(audioRef.currentTime)
    })
    audioRef.addEventListener('ended', () => {
      setCurrentTime(audioRef.currentTime)
      setPlay(false)
      audioRef.pause()
    })
    // mouse move

    return () => {
      // audioRef.pause();
      // audioRef.removeEventListener("canplaythrough", () => {});
      // audioRef.removeEventListener("timeupdate", () => {});
      // audioRef.removeEventListener("ended", () => {});
      // mouse move
    }
  }, [audioRef])

  useEffect(() => {
    setAudio(audioURL.data)
    props.setAudioRecording(audioURL.file)
  }, [audioURL])

  useEffect(() => {
    if (barProgress?.current && barAnchor?.current) {
      const clientRect = barProgress.current.getBoundingClientRect()
      const maxPosX = barProgress.current.clientWidth
      barProgress.current.addEventListener('mousemove', (event) => {
        onMove.current = true
        let posX = event.pageX - clientRect.left
        if (posX < 0) posX = 0
        if (posX > maxPosX) posX = maxPosX
        barAnchor.current.style.left = `${posX - 8}px`
        barAnchor.current.classList.add('anchor-moving')
      })
      barProgress.current.addEventListener('mouseleave', () => {
        onMove.current = false
        barAnchor.current.classList.remove('anchor-moving')
        //move barAnchor
        // const ratio = (currentTime ? currentTime * 100 / duration : 0.5)
        // const width =  ratio / 100 * (barProgress.current.clientWidth)
        // barAnchor.current.style.left = Math.floor(width).toString() + 'px'
      })
    }
    return () => {
      if (barProgress?.current && barAnchor?.current) {
        barProgress.current.removeEventListener('mousemove', () => {
          onMove.current = false
          barAnchor.current.classList.remove('anchor-moving')
        })
        barProgress.current.removeEventListener('mouseleave', () => {
          onMove.current = false
          barAnchor.current.classList.remove('anchor-moving')
        })
      }
    }
  }, [barProgress])

  const onRecording = async () => {
    if (audio && audio !== '') return

    if (!record) {
      startRecording()
      setTimeout(async () => {
        await stopRecording()
        // }, 30000) // 30 sec
      }, 300000) // 5 min
    } else {
      if (isRecording === true) {
        await stopRecording()
      } else {
        alert('Give permission audio to record')
      }
    }
    setRecord(!record)
  }

  const onReset = async () => {
    await stopRecording(true)
    setRecord(false)
    setPlay(false)
    setDuration(0)
    setCurrentTime(0)
    audioRef?.pause()
    setAudio('')
  }

  useEffect(() => {
    if (!props?.currentAudioRecording.size || props?.currentAudioRecording.size === undefined) onReset()
  }, [props?.currentAudioRecording])

  const onPlay = () => {
    if (!audio || audio === '') return

    if (!play) {
      audioRef.play()
    } else {
      audioRef.pause()
    }
    setPlay(!play)
  }
  // scroll audio
  const onScrollAudio = (event) => {
    const clientRect = barProgress.current.getBoundingClientRect()
    const posX = event.pageX - clientRect.left - 8
    const ratio = posX / barProgress.current.clientWidth
    audioRef.currentTime = audioRef.duration * ratio
  }
  // use memo for time audio
  const ratio = currentTime ? (currentTime * 100) / duration : 0
  const width = (ratio / 100) * barProgress?.current?.clientWidth
  if (barProgress?.current && barAnchor?.current) {
    barRef.current.style.width = Math.ceil(ratio).toString() + '%'
    if (!onMove?.current) {
      barAnchor.current.style.left = Math.ceil(width).toString() + 'px'
    }
  }

  return (
    <>
      <div className='w-row m-flx align-center audio-box'>
        <div className='w-col-10 w-col-medium-9 w-col-small-12 m-al-l'>
          <div className='w-row section-audio'>
            <div className='w-col-2 w-col-medium-3 w-col-tiny-3 m-flx'>
              <div className={record ? 'audio-play mr-4' : 'record-audio'} onClick={onRecording}>
                <i className='fa fa-microphone'></i>
              </div>
              <div className='record-audio audio-play' onClick={onPlay}>
                <i className={!play ? 'fa fa-play' : 'fa fa-pause'}></i>
              </div>
            </div>
            <div className='w-col-10 w-col-medium-9 w-col-tiny-9  w-col-small-12 section-progress-bar'>
              <span>{currentTimeText}</span>
              <div className='audio-progress-bar' ref={barProgress} style={{ position: 'relative' }}>
                <div className='bar' ref={barRef} />
                <div className='hide bar-scroll' onClick={onScrollAudio} />
                <div className='bar-anchor' ref={barAnchor} onClick={onScrollAudio} />
              </div>
              <span>{isRecording ? convertAudioTime(recordingLength) : durationTimeText} </span>
            </div>
            {/* <div className='w-col-1 w-col-medium-1 w-col-tiny-1 w-col-small-12 pl-5 m-flx backward-forward-btn'>
                          <div className='forward-btn pr-10 m-gray-3'>
                            <i className='fa fa-undo'></i>
                          </div>
                          <div className='backward-btn'>
                            <i className='fa fa-repeat'></i>
                          </div>
                        </div> */}
          </div>
        </div>
        <div className='w-col-2 w-col-medium-3 w-col-small-12  m-al-l'>
          <div className='reset-btn ml-10'>
            <button
              className={location?.pathname !== '/dashboard' ? 'button-2 bg-light-gray w-button' : 'button-2 w-button'}
              onClick={() => props.setAudioRecording({})}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  currentAudioRecording: state.recorder.currentAudioRecording,
})
const mapActionsToProps = {
  setAudioRecording,
}

export default connect(mapStateToProps, mapActionsToProps)(Recorder)
