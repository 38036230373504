import { SET_ERRORS, SET_QUIZ, SET_SUBMIT_QUIZ, SET_GENERAL_RESULT } from '../types'

import MAAYOT_API, { headers } from './config'

export const getQuiz = (id: string, level: string, memberId: string) => (dispatch: any) => {
  return new Promise((resolve, reject) => {
    fetch(`${MAAYOT_API}quiz/?storyId=${id}&level=${level?.toLowerCase() || ''}&memberId=${memberId}`, {
      method: 'GET',
      headers,
    })
      .then((resData) => resData.json())
      .then((data) => {
        if (data && !data.status && !data.statusCode && Object.keys(data) && Object.keys(data).length > 0) {
          const temp: any = []
          const dataQuiz = {
            quiz: data.quiz,
            options: (data.options || []).map((opt, index) => ({ label: opt, value: index + 1 })),
            memberAnswer: data.memberAnswer && { ...data.memberAnswer.data, rightAnswer: data.rightAnswer },
            optionsWithContent: data.optionsWithContent,
            quizContent: data.quizContent,
            allquizopContent: data.optionsWithContent.map((el) => {
              temp.push(...el.opContect, ...data.quizContent)
              return temp
            }),
          }
          dispatch({
            type: SET_QUIZ,
            payload: dataQuiz,
          })
          resolve(dataQuiz)
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: 'Error',
          })
          reject('Error')
        }
      })
  })
}

export const submitQuiz = (id: string, level: string, memberId: string, value: string) => (dispatch: any) => {
  return new Promise((resolve, reject) => {
    const content = {
      memberId,
      level: level?.toLowerCase() || '',
      storyId: id,
      answerSelected: value,
    }
    return fetch(`${MAAYOT_API}quiz`, {
      method: 'POST',
      headers,
      body: JSON.stringify(content),
    })
      .then((resData) => resData.json())
      .then((data) => {
        if (data && !data.status && Object.keys(data) && Object.keys(data).length > 0) {
          dispatch({
            type: SET_SUBMIT_QUIZ,
            payload: data,
          })
          resolve(data)
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: data.message.message,
          })
          reject(data.message.message)
        }
      })
  })
}

export const getGeneralResult = (id: string) => (dispatch: any) => {
  return new Promise((resolve, reject) => {
    fetch(`${MAAYOT_API}quiz/result?storyId=${id}`, {
      method: 'GET',
      headers,
    })
      .then((resData) => resData.json())
      .then((data) => {
        if (data && !data.status && data && Object.keys(data) && Object.keys(data).length > 0) {
          dispatch({
            type: SET_GENERAL_RESULT,
            payload: data,
          })
          resolve(data)
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: data.message.message,
          })
          reject(data)
        }
      })
  })
}
