import React, { useState, useEffect, useRef, useMemo } from 'react'
import { FreePlanFeatures, PremiumPlanFeatures, StandardPlanFeatures, ProPlanFeatures } from './const'
import { membershipNameType, PlanChangeLink } from '../../shared/constant'
// import UpgradeButton from './UpgradeButton'
import ReactModal from 'react-modal'
import { customStyles } from './styles'
import moment from 'moment'

interface IFeatureItemProps {
  label: string
  checked?: boolean
}

const selectedIcon = <i className='fa fa-check-circle color-primary' />
const unSelectedIcon = <i className='fa fa-times-circle color-error' />

const FeatureItem: React.FC<IFeatureItemProps> = (props: any) => {
  const { label, checked } = props
  return (
    <div className={'membership-feature-item'}>
      {checked ? selectedIcon : unSelectedIcon}
      <span className={'m-sm-text'}>{label}</span>
    </div>
  )
}
interface IMemberShipFeatures {
  features: any
}
const MemberShipFeatures: React.FC<IMemberShipFeatures> = (props: any) => {
  const { features = [] } = props
  return features.map((labelObj, index) => {
    return <FeatureItem label={labelObj?.label || ''} checked={!!labelObj.checked} key={index} />
  })
}

interface IDowngradeConfirmProps {
  isOpen: boolean
  message?: string
  onCancel: any
  onSubmit: any
  expiredDate: number
}

const DownGradeConfirmPopup: React.FC<IDowngradeConfirmProps> = (props: IDowngradeConfirmProps) => {
  const { isOpen, onCancel, onSubmit, expiredDate } = props
  const currentDate = useRef(moment.utc().unix()).current
  const countBeforeExpired = useMemo(() => {
    if (expiredDate) {
      const _currentDate = currentDate / 86400
      const _expiredDate = expiredDate / 86400
      return _currentDate - _expiredDate + 20 >= 10 && Math.round(_expiredDate - _currentDate)
    }
  }, [expiredDate])
  const onRequestClose = () => {
    onCancel()
  }
  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      <div className={'m-popup-confirm m-flx m-flx-col'}>
        <div className={'m-h5 m-gray-1 m-al-c'}>ARE YOU SURE?</div>
        <div className={'m-popup-body'}>
          <div className={'m-al-c'}>
            {(countBeforeExpired &&
              `You will lose ${countBeforeExpired} upcoming ${
                countBeforeExpired === 1 ? 'story' : 'stories'
              }, are you sure you wish to proceed?`) ||
              `You will lose your upcoming ${
                countBeforeExpired === 1 ? 'story' : 'stories'
              }, are you sure you wish to proceed?`}
          </div>
        </div>
        <div className={'popup-button m-flx m-center downgrade-confirm-btn '}>
          <button className={'m-b-gray-4 button-4 m-button-default'} onClick={onCancel}>
            Cancel
          </button>
          <button className={'m-b-primary-1 button-4'} onClick={onSubmit}>
            Downgrade
          </button>
        </div>
      </div>
    </ReactModal>
  )
}

interface IMemberShipProps {
  credentials?: any
  membership?: any
}

const MemberShip: React.FC<IMemberShipProps> = (props: any) => {
  const { membership, credentials } = props
  const { membership: membershipCredentials } = credentials || {}
  const [isOpenModal, setIsOpenModal] = useState(false)
  // eslint-disable-next-line
  const [canClick, setCanClick] = useState(false)
  useEffect(() => {
    // CheckAuthentication();
  }, [])

  const [title, setTitle] = useState('')
  const [titleClassName, setTitleClassName] = useState('')
  useEffect(() => {
    let title = '',
      titleclassName = ''
    if (membership) {
      switch (membership.name) {
        case membershipNameType.Premium:
          title = 'Premium Plan'
          titleclassName = 'm-b-primary'
          break
        case membershipNameType.School:
          title = 'School Plan'
          titleclassName = 'm-b-primary-2'
          break
        case membershipNameType.Standard:
          title = 'Standard Plan'
          titleclassName = 'm-b-primary-2'
          break
        case membershipNameType.Free:
          title = 'Free Plan'
          titleclassName = 'm-b-gray-1'
          break
        case membershipNameType.Pro:
          title = 'Pro Plan'
          titleclassName = 'm-b-error'
          break
      }
    }
    setTitle(title)
    setTitleClassName(titleclassName)
  }, [membership])
  const aRef = useRef<HTMLAnchorElement>(null)
  const onShowDowngradeForm = () => {
    setIsOpenModal(false)
    if (aRef?.current) {
      aRef.current?.click()
    }
  }
  const isFree = membership?.name == membershipNameType.Free
  const isPremium = membership?.name == membershipNameType.Premium
  const isStandard = membership?.name == membershipNameType.Standard
  const isPro = membership?.name == membershipNameType.Pro
  const isSchool = membership?.name == membershipNameType.School
  return (
    <>
      <div className='membership-right m-b-white'>
        <h4 className={'m-h4 m-gray-1'}>My Plan</h4>
        <div className={'membership-session'}>
          <div className={`membership-title ${titleClassName}`}>
            <span className={'color-white m-h5'}>{title}</span>
          </div>
          <div className={'membership-content'}>
            <div className={'w-row'}>
              <div className='w-col w-col-8'>
                {(isFree && <MemberShipFeatures features={FreePlanFeatures} />) ||
                  (isPremium && <MemberShipFeatures features={PremiumPlanFeatures} />) ||
                  (isPro && <MemberShipFeatures features={ProPlanFeatures} />) ||
                  ((isStandard || isSchool) && <MemberShipFeatures features={StandardPlanFeatures} />) ||
                  null}
              </div>
              <div className='w-col w-col-4 m-al-c m-flx m-flx-col'>
                {/* {(isFree && <UpgradeButton label={'Upgrade'} />) || */}
                {(isFree && (
                  <a href={PlanChangeLink} className='button-4 w-inline-block button-normal' target={'_blank'}>
                    Upgrade
                  </a>
                )) ||
                  (isPremium && (
                    <>
                      <div className={`m-b-gray-3 m-button ${canClick ? 'enable' : 'disable'}`}>Upgrade</div>
                      <div className={'cancel-btn'}>
                        <a className={'m-gray-2 m-pointer m-h6  m-link'} onClick={() => setIsOpenModal(true)}>
                          {/* <a className={'m-gray-2 m-pointer m-h6  m-link'} href={PlanChangeLink} target={'_blank'}> */}
                          Downgrade
                        </a>
                        {/*<a className={'m-gray-2 m-pointer m-h6 m-link'} href={'#/ms/signup/5f1cd9369b619b0004e23357'}>*/}
                        {/*  Downgrade*/}
                        {/*</a>*/}
                      </div>
                    </>
                  )) ||
                  ((isStandard || isSchool) && (
                    <>
                      {/* <UpgradeButton
                        label={'Upgrade'}
                        // linkTo={'#/ms/signup/6037247660f8090004eb07fa'}
                        // membershipId='6037247660f8090004eb07fa'
                        linkTo={'#/ms/signup/62e376bdad31350004f51767'}
                        membershipId='62e376bdad31350004f51767'
                      /> */}
                      <a href={PlanChangeLink} className='button-4 w-inline-block button-normal' target={'_blank'}>
                        Upgrade
                      </a>
                      <div className={'cancel-btn'}>
                        <a
                          className={'m-gray-2 m-pointer m-h6 m-link'}
                          // href={'https://www.maayot.com/plan-selection'}
                          href={PlanChangeLink}
                          target={'_blank'}
                        >
                          Compare plans
                        </a>
                      </div>
                    </>
                  ))}
              </div>
            </div>
            <div>
              {((isPremium || isStandard || isSchool || isPro) && (
                <div className={'cancel-btn'}>
                  {/* <a ref={aRef} className={'m-hidden'} href={'#/ms/signup/5ef93844eed2c40004936aeb'}></a> */}
                  <a ref={aRef} className={'m-hidden'} href={PlanChangeLink} target={'_blank'}></a>
                  <a className={'m-gray-2 m-pointer m-h6  m-link'} onClick={() => setIsOpenModal(true)}>
                    Downgrade
                  </a>
                </div>
              )) ||
                null}
            </div>
          </div>
        </div>
      </div>
      <DownGradeConfirmPopup
        isOpen={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        onSubmit={onShowDowngradeForm}
        expiredDate={membershipCredentials?.current_period_end}
      />
    </>
  )
}
export default MemberShip
