import React, { useEffect } from 'react'
import { MemberStepInStory, StepMap2Link } from '../../shared/constant'
import { connect } from 'react-redux'
import store from '../../redux/stores'
import { useNavigate } from 'react-router-dom'

interface IWithRedirectProps {
  preventRedirect?: boolean
  step?: any
  lastStep?: any
  story?: any
}
const WithRedirect = (WrappedComponent) => {
  const MainComponent: React.FC<IWithRedirectProps> = (props: IWithRedirectProps) => {
    const lastStep = store.getState()?.story?.currentStory?.lastStep
    const { story, preventRedirect } = props
    const navigate = useNavigate()

    let isStoryPersist = localStorage.getItem('persist:storymaayot')
    isStoryPersist = isStoryPersist ? JSON.parse(isStoryPersist) : isStoryPersist
    useEffect(() => {
      if (lastStep && story.id && !preventRedirect) {
        if (lastStep === MemberStepInStory.INTRODUCTION || lastStep === MemberStepInStory.FINISH) {
          navigate(`${StepMap2Link[lastStep]}`)
        } else {
          navigate(`${StepMap2Link[lastStep]}/${story.id}`)
        }
      }
    }, [lastStep, story.id, story])
    const currstory = { ...props, story: isStoryPersist ? props.story : {} }
    return <WrappedComponent {...currstory} />
  }

  const mapStateToProps = (state: any) => ({
    lastStep: state.story.lastStep,
    story: state.story.currentStory,
    preventRedirect: state.story.preventRedirect,
  })
  return connect(mapStateToProps)(MainComponent)
}
export default WithRedirect
